import {
	Button, Divider, Form, Input, message,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import './attempt-mq.scoped.css';

interface MQFormValues {
	[key: number]: string
}

interface AttemptMQProps {
	questionData: AttemptQuestionData
	submitting: boolean
	disableCopyPaste?: boolean
	onSubmit: (data: any) => Promise<void>
	setAttempt: (attemptData: unknown) => void
	getAttempt: () => any | null
	sendPasteNotification: () => void,
}

export const AttemptMQ: React.FunctionComponent<AttemptMQProps> = (props) => {
	const {
		questionData, submitting: isSubmitting, disableCopyPaste, onSubmit, setAttempt, getAttempt,
		sendPasteNotification,
	} = props;
	const [form] = Form.useForm();

	const [token, setToken] = useState<string | null>(null);
	const [stringCopied, setStringCopied] = useState<string>('');

	const saveAttempt = useCallback(() => {
		setAttempt({
			values: Object.values(form.getFieldsValue()),
		});
	}, [form, setAttempt]);

	const handleCopy = useCallback(async (ev: React.ClipboardEvent<HTMLTextAreaElement>) => {
		try {
			const newToken = crypto.randomUUID();
			await navigator.clipboard.writeText(newToken);
			if (ev.target) {
				const element = (ev.target as HTMLTextAreaElement);
				const start = element.selectionStart;
				const end = element.selectionEnd;
				const content = element.value;
				const contentToCopy = content.slice(start, end);
				setStringCopied(contentToCopy);
			}
			setToken(newToken);
		} catch (error) {
			console.log(error);
		}
	}, [setToken, setStringCopied]);

	const handlePaste = useCallback(async (ev: React.ClipboardEvent<HTMLTextAreaElement>) => {
		try {
			const data = await navigator.clipboard.readText();
			if (!data) {
				return;
			}
			if (data !== token) {
				message.error('Attention: Please do not use copy paste as it may result in disqualification.');
				sendPasteNotification();
				return;
			}
			if (ev.target) {
				const element = (ev.target as HTMLTextAreaElement);
				const start = element.selectionStart;
				const end = element.selectionEnd;
				const content = element.value;
				const newContent = content.slice(0, start) + stringCopied + content.slice(end);
				element.value = newContent;
				form.setFieldValue(element.id, newContent);
			}
		} catch (error) {
			console.log(error);
		}
	}, [token, stringCopied, form, sendPasteNotification]);

	useEffect(() => {
		const intervalId = setInterval(saveAttempt, 2000);

		return () => clearInterval(intervalId);
	}, [saveAttempt]);

	const handleMQFormSubmit = useCallback(async (values: MQFormValues) => {
		await onSubmit({
			isStepwise: true,
			stepwiseUserInput: JSON.stringify(Object.values(values)),
		});
	}, [onSubmit]);

	return (
		<div className="attempt-mq-container">
			<div>
				<span className="info-text">Answer below:</span>
				<Divider style={{ margin: '0.5rem 0 1.5rem 0', width: '50%', minWidth: 'unset' }} />
				<div>
					<Form form={form} layout="vertical" onFinish={handleMQFormSubmit}>
						{
							questionData.questionTypeMQ?.testCases?.map((testCase, index) => (
								<Form.Item
									key={testCase._id}
									name={index}
									label={(
										<div className="mq-details-container">
											<div className="mq-index">
												<span>{index + 1}</span>
											</div>
											<div className="mq-details">
												<pre>
													{testCase.input}
												</pre>
											</div>
										</div>
									)}
									initialValue={(
										getAttempt()?.values?.[index]
										?? questionData.questionTypeMQ?.lastSubmitted?.[index]
									)}
								>
									<Input.TextArea
										allowClear
										autoSize={{ minRows: 3, maxRows: 5 }}
										disabled={isSubmitting}
										onCopy={(ev) => {
											if (disableCopyPaste) {
												ev.preventDefault();
												handleCopy(ev);
											}
										}}
										onPaste={(ev) => {
											if (disableCopyPaste) {
												ev.preventDefault();
												handlePaste(ev);
											}
										}}
									/>
								</Form.Item>
							))
						}
					</Form>
				</div>
			</div>
			<div className="submit-mq-container">
				<Button
					type="primary"
					size="large"
					onClick={() => form.submit()}
					loading={isSubmitting}
				>
					submit
				</Button>
			</div>
		</div>
	);
};
