import { message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import {
	Switch, Route, useRouteMatch, Redirect, useHistory,
} from 'react-router-dom';
import { AppHeader } from '../../components/app-header';
import { useAppStore, useQuizStore, useSessionStore } from '../../store';
import { QuizAttemptScreen } from './quiz-attempt';
import { QuizDashboardScreen } from './quiz-dashboard';
import { QuizDetailsScreen } from './quiz-details';
import { QuizLoginScreen } from './quiz-login';
import { APP_CONFIG, NetworkStatus, isElectronApp } from '../../config';
import { QuizInvitePage } from './quiz-invite-page';

const sessionStoreSelector = (state: SessionState) => ({
	isAuthenticated: state.isAuthenticated,
	sessionData: state.sessionData,
});

const quizStoreSelector = (state: QuizState) => ({
	hasStarted: state.hasStarted,
	quizData: state.quizData,
	timeLeft: state.timeLeft,
	tabSwitchCount: state.tabSwitchCount,
	tabSwitchInCount: state.tabSwitchInCount,
	fullScreenInCount: state.fullScreenInCount,
	fullScreenOutCount: state.fullScreenOutCount,
	nextLink: state.nextLink,
	previousLink: state.previousLink,
	submittedQuestions: state.submittedQuestions,
	setQuizTimeLeft: state.setQuizTimeLeft,
	getRemainingTime: state.getRemainingTime,
	submitQuiz: state.submitQuiz,
});

const appStoreSelector = (state: AppState) => ({
	isOnline: state.isOnLine,
	isSocketConnected: state.isSocketConnected,
});

export const QuizScreen: React.FunctionComponent = () => {
	const match = useRouteMatch();
	const history = useHistory();

	const { isAuthenticated, sessionData } = useSessionStore(sessionStoreSelector);
	const { isOnline, isSocketConnected } = useAppStore(appStoreSelector);
	const {
		nextLink, previousLink,
		hasStarted, quizData, submittedQuestions,
		tabSwitchCount,
		tabSwitchInCount,
		fullScreenInCount,
		fullScreenOutCount,
		timeLeft, setQuizTimeLeft, getRemainingTime, submitQuiz,
	} = useQuizStore(quizStoreSelector);

	const [networkStatus, setNetworkStatus] = useState<NetworkStatus>(NetworkStatus.Online);
	useEffect(() => {
		if (isSocketConnected) {
			return setNetworkStatus(NetworkStatus.Online);
		}
		if (isOnline) {
			return setNetworkStatus(NetworkStatus.Pending);
		}
		return setNetworkStatus(NetworkStatus.Offline);
	}, [isOnline, isSocketConnected, setNetworkStatus]);

	useEffect(() => {
		if (window.performance
			&& window.performance.navigation.type === window.performance.navigation.TYPE_BACK_FORWARD) {
			console.log('baccckckkkk btn 1');
			history.push({
				state: { referrer: undefined },
			});
			// window.location.reload();
		}
	}, [history]);

	const invitePage = useCallback((params) => {
		message.config({
			top: 55,
		});

		return (
			// eslint-disable-next-line no-nested-ternary
			quizData && quizData?.isAppOnly
				? isElectronApp
					? (
						<Redirect
							exact
							to={{
								pathname: params.location.state?.referrer ?? `${match.url}/${params.match.params.quizName}/login`,
							}}
						/>
					)
					: (
						<QuizInvitePage
							token={params.match.params.token}
							quizName={params.match.params.quizName}
						/>
					)
				: <></>
		);
	}, [match.url, quizData]);

	const quizLoginScreen = useCallback((params) => {
		message.config({
			// Set the message alert just below app header
			top: 55,
		});

		return (
			<>
				{
					!isAuthenticated
						? (
							<QuizLoginScreen
								quizName={params.match.params.quizName}
							/>
						) : (
							<Redirect
								exact
								to={{
									pathname: params.location.state?.referrer ?? `${match.url}/${params.match.params.quizName}/details`,
									// state: {
									// 	referrer: params.location.state?.referrer ?? params.location.pathname,
									// },
								}}
							/>
						)
				}
			</>
		);
	}, [isAuthenticated, match]);

	const quizDetailsScreen = useCallback((params) => {
		message.config({
			top: 55,
		});

		return (
			<>
				{
					isAuthenticated
						? (
							<>
								{
									hasStarted
										? (
											<Redirect
												exact
												to={{
													pathname: params.location.state?.referrer ?? `${match.url}/${params.match.params.quizName}/dashboard`,
													// state: {
													// 	referrer: params.location.state?.referrer
													// 	?? params.location.pathname,
													// },
												}}
											/>
										) : (
											<>
												<AppHeader
													quizName={params.match.params.quizName}
													quizData={quizData}
													sessionData={sessionData}
													networkStatus={networkStatus}
												/>
												<QuizDetailsScreen
													quizName={params.match.params.quizName}
												/>
											</>
										)
								}
							</>
						) : (
							<Redirect
								exact
								to={{
									pathname: `${match.url}/${params.match.params.quizName}/login`,
									state: {
										referrer: params.location.pathname,
									},
								}}
							/>
						)
				}
			</>
		);
	}, [isAuthenticated, hasStarted, quizData, sessionData, match, networkStatus]);

	const quizDashboardScreen = useCallback((params) => {
		message.config({
			top: 55,
		});

		return (
			<>
				{
					isAuthenticated
						? (
							<>
								{
									hasStarted
										? (
											<>
												<AppHeader
													quizName={params.match.params.quizName}
													quizData={quizData}
													sessionData={sessionData}
													hasQuizStarted={hasStarted}
													timeLeft={timeLeft}
													tabSwitchCount={tabSwitchCount}
													tabSwitchInCount={tabSwitchInCount}
													fullScreenInCount={fullScreenInCount}
													fullScreenOutCount={fullScreenOutCount}
													totalSubmittedQuestions={submittedQuestions.length}
													nextLink={nextLink}
													previousLink={previousLink}
													setTimeLeft={setQuizTimeLeft}
													getRemainingTime={getRemainingTime}
													submitQuiz={submitQuiz}
													networkStatus={networkStatus}
												/>
												<QuizDashboardScreen
													quizName={params.match.params.quizName}
												/>
											</>
										) : (
											<Redirect
												exact
												to={{
													pathname: `${match.url}/${params.match.params.quizName}/details`,
													state: {
														referrer: params.location.pathname,
													},
												}}
											/>
										)
								}
							</>
						) : (
							<Redirect
								exact
								to={{
									pathname: `${match.url}/${params.match.params.quizName}/login`,
									state: {
										referrer: params.location.pathname,
									},
								}}
							/>
						)
				}
			</>
		);
	}, [
		nextLink, previousLink, isAuthenticated,
		quizData, sessionData, submittedQuestions, hasStarted,
		match, timeLeft, setQuizTimeLeft, getRemainingTime, submitQuiz,
		tabSwitchCount,
		tabSwitchInCount,
		fullScreenInCount,
		fullScreenOutCount,
		networkStatus,
	]);

	const quizAttemptScreen = useCallback((params) => {
		message.config({
			top: 55,
		});

		return (
			<>
				{
					isAuthenticated
						? (
							<>
								{
									hasStarted
										? (
											<>
												<AppHeader
													quizName={params.match.params.quizName}
													quizData={quizData}
													sessionData={sessionData}
													hasQuizStarted={hasStarted}
													timeLeft={timeLeft}
													tabSwitchCount={tabSwitchCount}
													tabSwitchInCount={tabSwitchInCount}
													fullScreenInCount={fullScreenInCount}
													fullScreenOutCount={fullScreenOutCount}
													nextLink={nextLink}
													previousLink={previousLink}
													totalSubmittedQuestions={submittedQuestions.length}
													setTimeLeft={setQuizTimeLeft}
													getRemainingTime={getRemainingTime}
													submitQuiz={submitQuiz}
													showHome
													networkStatus={networkStatus}
												/>
												<QuizAttemptScreen
													quizName={params.match.params.quizName}
													questionId={params.match.params.questionId}
												/>
											</>
										) : (
											<Redirect
												exact
												to={{
													pathname: `${match.url}/${params.match.params.quizName}/details`,
													state: {
														referrer: params.location.pathname,
													},
												}}
											/>
										)
								}
							</>
						) : (
							<Redirect
								exact
								to={{
									pathname: `${match.url}/${params.match.params.quizName}/login`,
									state: {
										referrer: params.location.pathname,
									},
								}}
							/>
						)
				}
			</>
		);
	}, [
		nextLink, previousLink, isAuthenticated,
		quizData, sessionData, submittedQuestions, hasStarted,
		match, timeLeft, setQuizTimeLeft, getRemainingTime, submitQuiz,
		tabSwitchCount,
		tabSwitchInCount,
		fullScreenInCount,
		fullScreenOutCount,
		networkStatus,
	]);

	const redirectionToPreview = useCallback((params) => (
		<>
			{
				window.location.replace(`${APP_CONFIG.QuizServerURL}/test/${params.match.params.quizName}/${params.match.params.code}`)
			}
		</>
	), []);

	return (
		<Switch>

			<Redirect exact from={`${match.url}/:quizName`} to={`${match.url}/:quizName/login`} />

			<Route
				exact
				path={`${match.url}/:quizName/invite/:token`}
				render={invitePage}
			/>

			<Route
				exact
				path={`${match.url}/:quizName/login`}
				render={quizLoginScreen}
			/>

			<Route
				exact
				path={`${match.url}/:quizName/details`}
				render={quizDetailsScreen}
			/>

			<Route
				path={`${match.url}/:quizName/dashboard`}
				render={quizDashboardScreen}
			/>

			<Route
				exact
				path={`${match.url}/:quizName/attempt/:questionId`}
				render={quizAttemptScreen}
			/>

			<Redirect to="/" />
		</Switch>
	);
};
