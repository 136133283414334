import create, { UseStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { CQQuizClient } from '../../clients';
import { immer } from '../middleware';
import { APP_CONFIG, ScreenShareState } from '../../config';
import { AIProctor } from '../../libs/object-dection/object-detection';

enum AppAction {
	SetAppLoading = 'set-app-loading',
}

const initialState: AppState = {
	isOnLine: true,
	isSocketConnected: true,
	disconnectionTimeOut: null,
	loading: false,
	loadingText: '',
	openInApp: false,
	appModalShown: false,
	screenShareState: ScreenShareState.Disabled,
	room: null,
	aiProctoring: null,
	setAppModalShown: () => {},
	setAppLoading: async () => {},
	handleOpenInApp: async () => {},
	downloadApp: async () => {},
	openLinkInApp: () => {},
	setRoom: () => {},
	setScreenShareState: () => {},
	setAiProctoring: (aiProctoring: AIProctor) => {},
};

export function createAppStore(cqQuizClient: CQQuizClient): UseStore<AppState> {
	return create<AppState>(immer(devtools((set, get) => {
		cqQuizClient.on('socket-disconnected', () => {
			set((state) => {
				// eslint-disable-next-line no-param-reassign
				state.isSocketConnected = false;
				// eslint-disable-next-line no-param-reassign
				state.screenShareState = setTimeout(() => {
					// eslint-disable-next-line no-param-reassign
					set((stateInner) => {
						// eslint-disable-next-line no-param-reassign
						stateInner.isOnLine = false;
					});
				}, 4000);
			});
		});
		cqQuizClient.on('socket-connected', () => {
			set((state) => {
				if (get().screenShareState) {
					clearInterval(get().screenShareState);
				}
				// eslint-disable-next-line no-param-reassign
				state.isSocketConnected = true;
				// eslint-disable-next-line no-param-reassign
				state.isOnLine = true;
			});
		});
		return {
			...initialState,

			setAppLoading: async (isLoading: boolean, loadingText?: string): Promise<void> => {
				set({
					loading: isLoading,
					loadingText: loadingText || '',
				}, false, AppAction.SetAppLoading);

				return new Promise((resolve) => setTimeout(resolve, 0));
			},

			downloadApp: async (platform?: string): Promise<void> => {
				await cqQuizClient.downloadApp(platform);
			},

			openLinkInApp: (url) => {
				const iframe = document.getElementById('openAppIfram');
				(iframe as HTMLIFrameElement).src = `${APP_CONFIG.ElectronScheme}://${url}`;
			},
			setAppModalShown: (value: boolean) => {
				set({
					appModalShown: value,
				});
			},
			setRoom: (room) => {
				set({
					room,
				});
			},
			setScreenShareState: (value: ScreenShareState) => {
				set({
					screenShareState: value,
				});
			},

			handleOpenInApp: async (state: boolean, link?: string) => {
				if (state) {
					set({
						openInApp: true,
					});
					get().openLinkInApp(link ?? window.location.href);
				} else {
					set({
						openInApp: false,
					});
				}
			},
		};
	}, 'AppStore')));
}
