import { logger } from '../logger';

async function sleep(time: number): Promise<void> {
	return new Promise((resolve) => {
		const timeoutId = setTimeout(() => {
			clearTimeout(timeoutId);
			return resolve();
		}, time);
	});
}

export async function webTestsRunner(window: Window, evaluator: string): Promise<boolean> {
	const previewFrameWindow = window;
	const previewFrameDocument = window.document;

	let result = false;
	try {
		// eslint-disable-next-line no-eval
		result = await eval(evaluator);
	} catch (e: any) {
		logger.info(e.message);
	}

	return !!result;
}
