// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".openInApp_appOnlyMessage__1LJ6t {\n\tmargin: 0px;\n}", "",{"version":3,"sources":["webpack://src/components/open-in-app/openInApp.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;AACZ","sourcesContent":[".appOnlyMessage {\n\tmargin: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appOnlyMessage": "openInApp_appOnlyMessage__1LJ6t"
};
export default ___CSS_LOADER_EXPORT___;
