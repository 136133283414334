import { EventEmitter } from 'events';
import TypedEmitter from 'typed-emitter';
import axios, { AxiosInstance, Method } from 'axios';
import { Socket } from 'socket.io-client';
import { createSocket } from '../../libs/socket';
import { logger } from '../../libs/utils/logger';
import {
	APP_CONFIG, QuestionType, TestCaseStatus, isElectronApp,
} from '../../config';
import { currentPlatform } from '../../libs/utils';

interface CQQuizClientOptions {
	quizServerURL: string
	staticStorageApiURL: string
}

interface AuthSucceedResponse {
	msg: string
	id: string // session id
}

interface ClientEvents {
	'auth': (data: SessionData) => Promise<void>

	'socket-connected': () => void
	'socket-disconnected': () => void
	'socket-auth': () => void
	'compile-result': (data: any) => void
	'section-update': () => void
	'force-submit': () => void

	'quiz-started': (data: any) => Promise<void>
	'quiz-data-received': (data: QuizData) => Promise<void>
	'quiz-dashboard-data-received': (data: any) => Promise<void>
	'quiz-segment-submitted': (segmentIndex: number) => Promise<void>
	'quiz-submitted': () => Promise<void>
	'concurrent_user_exeded': () => void
	'attempt-question-data-received': (data: AttemptQuestionData) => Promise<void>
	'question-submitted': (questionId: string) => Promise<void>
	'quiz-updated': (updatedAt: string) => Promise<void>
	'quiz-remaining-time-updated': (time: number) => Promise<void>
	'quiz-suspicious-updated': (data: any) => Promise<void>
	'server-time': (data: any) => Promise<void>
}

interface SocketEmitEvents {
	notification: (data: any) => void
	compile: (compileRequest: CodeCompilationRequest, ack: (error?: unknown) => void) => void
	update_jitsi_credentials: (data: NonNullable<JitsiCredentials>) => void
}

interface SocketListenEvents {
	auth_succeed: (authSucceedResponse: AuthSucceedResponse) => void
	compile: (compileResult: any) => void
	session_expired: (data: any) => void
	logout_user: (data: any) => void
	concurrent_user_exeded: (data: any) => void
	force_submit: () => void
	section_update: () => void
}

interface mainApiRequestParams {
	method: Method;
	url: string, params?: any;
	payload?: any;
	config?: any;
	headers?: any;
}

export class CQQuizClient extends (EventEmitter as new () => TypedEmitter<ClientEvents>) {
	private _options: CQQuizClientOptions;

	private _socket: Socket<SocketListenEvents, SocketEmitEvents>;

	private _mainAPI: AxiosInstance;

	private _staticStorageAPI: AxiosInstance;

	private _sessionData?: SessionData;

	private quizId: string | null;

	private _disposables: (() => void)[] = [];

	private _quizServerUrl: string;

	constructor(options: CQQuizClientOptions) {
		super();
		const { quizServerURL, staticStorageApiURL } = options;

		this.quizId = null;

		const socket = createSocket({
			path: '/socket.io',
			endpoint: quizServerURL,
		});

		const mainAPI = axios.create({
			baseURL: quizServerURL,
			responseType: 'json',
			withCredentials: true,
		});

		const staticStorageAPI = axios.create({
			baseURL: staticStorageApiURL,
			responseType: 'json',
			withCredentials: true,
		});

		this._socket = socket;
		this._mainAPI = mainAPI;
		this._staticStorageAPI = staticStorageAPI;
		this._options = options;
		this._quizServerUrl = quizServerURL;

		this._attachClientListeners();
		this._attachSocketListeners();

		this._disposables.push(
			this._detachClientListeners,
			this._detachSocketListeners,
			this.removeAllListeners.bind(this),
		);
	}

	private _onAuth = async (sessionData: SessionData): Promise<void> => {
		this._sessionData = {
			...this._sessionData,
			...sessionData,
		};
	};

	private _attachClientListeners = (): void => {
		this.on('auth', this._onAuth);
	};

	private _detachClientListeners = (): void => {
		this.off('auth', this._onAuth);
	};

	private _submitQuiz = (...args): void => {
		const callback = args.pop();
		if (callback && typeof callback === 'function') {
			callback({
				status: 'ok',
			});
		}
		this.emit('force-submit');
	};

	private _onSocketConnect = (): void => {
		this.emit('socket-connected');
	};

	private _onSocketDisconnect = (): void => {
		this.emit('socket-disconnected');
	};

	private _onSectionUpdate = (): void => {
		this.emit('section-update');
	};

	private _onConcurrentUserExeded = (): void => {
		localStorage.setItem('socketLogout', JSON.stringify({
			date: Date.now(),
			msg: 'Concurrent User Exeeded.',
		}));
		this.emit('socket-disconnected');
	};

	private _onSocketAuthSucceed = (payload: AuthSucceedResponse): void => {
		const { id: sessionId } = payload || {};
		if (!sessionId) throw new Error('auth_succeed: sessionId not found');

		const { _sessionData } = this;
		if (!_sessionData) throw new Error('session data does not exist');

		const sessionData = {
			..._sessionData,
			sessionId,
		};

		this.emit('auth', sessionData);
		this.emit('socket-auth');
	};

	private _onSessionExpired = (data: any): void => {
		if (data && data.msg) {
			localStorage.setItem('socketLogout', JSON.stringify({
				date: Date.now(),
				msg: data.msg,
			}));
			window.location.reload();
		}
		window.location.reload();
	};

	private _onlogoutUser = (): void => {
		window.location.replace(`${APP_CONFIG.QuizServerURL}/logout`);
	};

	private _onCompileResult = (data: unknown): void => {
		this.emit('compile-result', data);
	};

	private _attachSocketListeners = (): void => {
		const { _socket } = this;

		_socket.on('connect', this._onSocketConnect);
		_socket.on('disconnect', this._onSocketDisconnect);

		_socket.on('auth_succeed', this._onSocketAuthSucceed);
		_socket.on('session_expired', this._onSessionExpired);
		_socket.on('logout_user', this._onlogoutUser);

		_socket.on('compile', this._onCompileResult);
		_socket.on('section_update', this._onSectionUpdate);
		_socket.on('concurrent_user_exeded', this._onConcurrentUserExeded);
		_socket.on('force_submit',	this._submitQuiz);
	};

	private _detachSocketListeners = (): void => {
		const { _socket } = this;

		_socket.off('connect', this._onSocketConnect);
		_socket.off('disconnect', this._onSocketDisconnect);

		_socket.off('auth_succeed', this._onSocketAuthSucceed);
		_socket.off('session_expired', this._onSessionExpired);
		_socket.off('logout_user', this._onlogoutUser);

		_socket.off('compile', this._onCompileResult);
	};

	get connected(): boolean {
		return this._socket.connected;
	}

	connect = async (): Promise<void> => {
		const { _socket, _sessionData } = this;

		if (!_sessionData) {
			throw new Error('connection cannot be created before user auth.');
		}

		return new Promise((resolve, reject) => {
			const timer = setTimeout(() => {
				reject(new Error('timeout waiting for connect-auth'));
			}, 10000);

			this.once('socket-auth', async () => {
				clearTimeout(timer);
				resolve();
			});

			_socket.connect();
		});
	};

	destroy = async (): Promise<void> => {
		const { _disposables } = this;

		await Promise.all(_disposables.map((el) => el()));
		this._disposables = [];
	};

	mainApiRequestSend = async (
		{
			method, url, params, payload, config, headers,
		}: mainApiRequestParams,
	): Promise<any> => {
		let isLogout = false;
		try {
			const response = await this._mainAPI({
				method,
				url,
				data: payload,
				params,
				...config,
				headers,
			});

			if (response.data.error && response.status === 401) {
				isLogout = true;
				throw new Error(response.data.error);
			}

			return response;
		} catch (error: any) {
			logger.log(error?.response);
			if (error?.response?.data?.error) {
				if (error?.response.status === 401) {
					isLogout = true;
				}
				if (error?.response.status === 207) {
					isLogout = true;
				}
				throw new Error(error.response.data.error);
			}
			if (error.message.indexOf('status code 401') !== -1) {
				isLogout = true;
			} else if (error.message.indexOf('Network Error') !== -1) {
				throw new Error('Please check your internet connection.');
			}
			throw new Error(error.message);
			return {
				data: error,
			};
		} finally {
			if (isLogout) {
				// need to change soon. Because we need to fix this issue soon that's why we did it.
				// message.error('Session Expired');
				// await (new Promise((res, rej) => {
				// 	setTimeout(res, 1000);
				// }));
				localStorage.setItem('forceSessionExpired', JSON.stringify(Date.now()));
				window.location.reload();
				await (new Promise((res) => {
					setTimeout(res, 3000);
				}));
			}
		}
	};

	loginUser = async (
		email: string, password: string, quizCode: string, quizName: string,
	): Promise<unknown> => {
		try {
			const platform = currentPlatform();
			const response = await this._mainAPI.post('/login/?isQuiz=1', {
				email,
				password,
				quizCode,
				quizName,
				platform,
			});

			if (response.status === 205) {
				if (isElectronApp) {
					setTimeout(() => {
						window.location.reload();
					}, 2000);
					throw new Error('Quiz has been updated. Reloading ..');
				} else {
					throw new Error('Test has been updated, Please reload and try again.');
				}
			}
			const data = response.data as unknown;

			return data;
		} catch (e: any) {
			logger.error(e?.response);
			throw new Error(e?.response?.data?.error ?? e?.message ?? e ?? 'Login Error: Unknown Error');
		}
	};

	registerUser = async (
		name: string, email: string, password: string, quizCode: string,
		mobile: string, quizName: string, quizCreatedBy: string, enrollmentId?: string,
	): Promise<unknown> => {
		try {
			// const response = await this._mainAPI.post('/batch/addstudent/guest/', {
			// 	stname: name,
			// 	stemail: email,
			// 	stpassword: password,
			// 	stcontactno: mobile,
			// 	quizCode,
			// 	quizName,
			// 	userCreatorId: quizCreatedBy,
			// 	role: '1',
			// });
			const response = await this.mainApiRequestSend({
				method: 'POST',
				url: '/addstudent/guest/',
				payload: {
					stname: name,
					stemail: email,
					stpassword: password,
					stcontactno: mobile,
					quizCode,
					quizName,
					userCreatorId: quizCreatedBy,
					enrollmentId,
					role: '1',
				},
			});

			if (response.data && response.data.error) {
				logger.error(response.data.error);
				throw new Error(response.data.error);
			}

			const data = response.data as unknown;

			return data;
		} catch (e: any) {
			logger.error(e.message);
			throw new Error(e.message || 'Register Error: Unknown Error');
		}
	};

	getSessionData = async (): Promise<void> => {
		try {
			const response = await this._mainAPI.get('/quiz-api/session/data');
			// const response = await this.mainApiRequestSend({
			// 	method: 'GET',
			// 	url: '/quiz-api/session/data',
			// });

			const data = response.data as SessionDataResponse;
			let { profilePic } = data.session;
			if (profilePic.indexOf('digitaloceanspaces') === -1) {
				profilePic = '';
			}
			const {
				userId, ssnid, role, email, roleId, displayname, tryTest,
			} = data.session;

			const {
				isClassAllowed,
				allowInteractiveMode,
				canAddTestCase,
				addQuestion,
				addCourse,
				addTutorial,
				addQuiz,
				addBatch,
				addUser,
				addAdminProject,
				projectSpace,
				testingSpace,
				learningSpace,
				projectLanguagesAllowed,
				languagesAllowed,
				quizId,
				authTokenForStream,
			} = data.session;

			if (this.quizId !== quizId) {
				throw new Error('Quiz id dees not match with session.');
			}

			const sessionData: SessionData = {
				userId,
				sessionId: ssnid,
				email,
				role: parseInt(role, 10),
				roleId,
				displayName: displayname,
				profilePic,
				tryTest: tryTest || false,

				stream: {
					authToken: authTokenForStream,
				},

				acl: {
					isClassAllowed,
					allowInteractiveMode,
					canAddTestCase,
					addQuestion,
					addCourse,
					addTutorial,
					addQuiz,
					addBatch,
					addUser,
					addAdminProject,
					projectSpace,
					testingSpace,
					learningSpace,

					projectLanguagesAllowed,
					languagesAllowed,
				},
			};

			this.emit('auth', sessionData);
		} catch (e: any) {
			logger.error(e.response);
			throw new Error(e?.response?.data?.error || e?.message || 'Error while getting session data.');
		}
	};

	getQuizData = async (quizId: string): Promise<void> => {
		// const response = await this._mainAPI.get(`/test/${quizId}?json=1`);
		const response = await this.mainApiRequestSend({
			method: 'GET',
			url: `/test/${quizId}?json=1`,
		});

		if (response.data?.error && response.data?.redirectUrl) {
			// console.error(response.data.error);
			// ip not allowed case
			// throw new Error(response.data.error || 'Ip not allowed');
			window.location.href = response.data.redirectUrl;
			return;
		}

		const qData = response?.data.quiz as QuizDataResponse;

		this.quizId = qData._id;

		const quizData: QuizData = {
			quizId: qData._id,
			title: qData.title,
			instructions: qData.instructions,
			startTime: qData.startTime,
			questions: qData.questions,
			quizTime: qData.quizTime,
			languagesAllowed: qData.languagesAllowed || [],
			quizParams: qData.quizParams || [],
			quizUserDetails: qData.quizUserDetails,
			attemptInSequence: qData.attemptInSequence === 'true' || qData.attemptInSequence === true,
			copyPasteAllowed: qData.copyPasteAllowed === 'true' || qData.copyPasteAllowed === true,
			isPrivate: qData.isPrivate === 'true' || qData.isPrivate === true,
			isSignUpAllowed: qData.isSignUpAllowed === 'true' || qData.isSignUpAllowed === true,
			isWebCamAllowed: (qData.isWebCamAllowed === 'true' || qData.isWebCamAllowed === true)
				|| (qData.isLiveStreamEnabled === 'true' || qData.isLiveStreamEnabled === true),
			randomizeQuestion: qData.randomizeQuestion === 'true' || qData.randomizeQuestion === true,
			revisitAllowed: qData.revisitAllowed === 'true' || qData.revisitAllowed === true,
			tabSwitchAllowed: qData.tabSwitchAllowed === 'true' || qData.tabSwitchAllowed === true,
			createdBy: qData.userCreatorId,
			updatedAt: qData.updatedAt,
			logo: qData.logo,
			isFullScreen: qData.isFullScreen === 'true' || qData.isFullScreen === true,
			isAppOnly: qData.isAppOnly === 'true' || qData.isAppOnly === true,
			isLiveStreamEnabled: qData.isLiveStreamEnabled === 'true' || qData.isLiveStreamEnabled === true,
			allowClose: qData.allowClose ? (qData.allowClose === 'true' || qData.allowClose === true) : false,
			invalidIp: qData.invalidIp,
			endTime: qData.endTime,
		};

		this.emit('quiz-data-received', quizData);
	};

	startQuiz = async (quizId: string, quizUserDetails?: any): Promise<void> => {
		if (this.connected) {
			// const response = await this._mainAPI.post(`/test/startQuiz/${quizId}`, {
			// 	quizUserDetails,
			// });
			const response = await this.mainApiRequestSend({
				method: 'POST',
				url: `/test/startQuiz/${quizId}`,
				payload: {
					quizUserDetails,
				},
			});

			this.emit('quiz-started', { remainingTime: response?.data?.remainingTime });
		} else {
			window.location.reload();
		}
	};

	getQuizDashboardData = async (quizId: string, isUpdate?: number): Promise<void> => {
		const response = await this.mainApiRequestSend({
			method: 'GET',
			url: `/test/dashboard/${quizId}`,
			params: {
				update: isUpdate || undefined,
			},
		});

		// const response = await this._mainAPI.get(`/test/dashboard/${quizId}`, {
		// 	params: {
		// 		update: isUpdate || undefined,
		// 	},
		// });

		const data = response?.data as QuizDashboardDataResponse;

		const dashboardData: QuizDashboardData = {
			segments: [],
			questionIds: data.userQuizSubmittedSegment.questionId,
		};

		const { revisitAllowed } = data.quiz;

		let startIndex = 0;

		dashboardData.segments = data.quiz.quizSegments.map((segment, index) => {
			const isSubmitted = data?.userQuizSubmittedSegment?.quizSubmittedSegments?.findIndex(
				(segIndex: string) => +segIndex === index,
			);

			const segmentData: QuizDashboardSegmentData = {
				_id: segment._id,
				title: segment.title,
				count: segment.count,
				isDisabled: isSubmitted < 0 && !revisitAllowed,
				isSubmitted: isSubmitted >= 0 && !revisitAllowed,
				questions: data.quiz.poolQuestion || data.quiz.randomizeQuestion
					? data.userQuizSubmittedSegment.questionId
						.slice(startIndex)
						.slice(0,
							data.quiz.poolQuestion && segment.pollNumber ? segment.pollNumber : segment.count)
						.map((qId) => data.quiz.quizContent.find((el) => el.id === qId))
						.filter((content) => !!(content && content.question))
						.map((content) => ({
							_id: content?.question._id,
							title: content?.question.title,
							type: content?.question.type ? +content.question.type : NaN,
							status: data.userQuizSubmittedSegment
								.submittedQuestions.findIndex((qId) => qId === content?.question._id) < 0
								? 0 : 1,
						}))
					: data.quiz.quizContent
						.slice(startIndex)
						.slice(0, segment.count)
						.filter((content) => !!(content && content.question))
						.map((content) => ({
							_id: content.question._id,
							title: content.question.title,
							type: +content.question.type,
							status: data.userQuizSubmittedSegment
								.submittedQuestions.findIndex((qId) => qId === content?.question._id) < 0
								? 0 : 1,
						})),
			};

			startIndex += data.quiz.poolQuestion ? segment.pollNumber : segment.count;

			return segmentData;
		});

		let lastSubmittedIndex = -1;
		dashboardData.segments.forEach((segment, index) => {
			if (segment.isSubmitted) {
				lastSubmittedIndex = index;
			}
		});

		if (lastSubmittedIndex >= 0) {
			const segment = dashboardData.segments[lastSubmittedIndex + 1];
			if (segment) {
				segment.isDisabled = false;
			}
		} else if (dashboardData.segments[0]) {
			dashboardData.segments[0].isDisabled = false;
		}

		this.emit('quiz-dashboard-data-received', {
			dashboardData,
			quiz: {
				...data.quiz,
				totalQuestions: data.quiz.poolQuestion
					? data.userQuizSubmittedSegment.questionId.length : data.quiz.questionId.length,
			},
			submittedQuestions: data.userQuizSubmittedSegment.submittedQuestions.filter((qId) => {
				let questionIds = data.quiz.questionId;
				if (data.userQuizSubmittedSegment.questionId?.length) {
					questionIds = data.userQuizSubmittedSegment.questionId;
				}

				return questionIds.includes(qId as string);
			}),
			hasQuizStarted: data.userQuizSubmittedSegment.hasQuizStarted,
		});
	};

	getAttemptQuestionData = async (quizId: string, questionId: string): Promise<void> => {
		// const response =
		// await this._mainAPI.get(`/attempt/attemptquestion/quiz/${quizId}/${questionId}`);
		const response = await this.mainApiRequestSend({
			method: 'GET',
			url: `/attempt/attemptquestion/quiz/${quizId}/${questionId}`,
		});

		const data = response.data as AttemptQuestionDataResponse;

		const attemptQuestionData: AttemptQuestionData = {
			_id: data.question._id,
			title: data.question.title,
			text: data.question.text,
			type: data.question.type,
			lastUsed: data.question.lastUsed,
			score: `${data.question.score}`,
			files: data.question.files,
		};

		if (+attemptQuestionData.type === QuestionType.MCQ && data.question.questionTypeMCQ) {
			attemptQuestionData.questionTypeMCQ = {
				correctAnswers: data.question.questionTypeMCQ.correctAnswers,
				options: data.question.questionTypeMCQ.options,
				showExplanation: data.question.questionTypeMCQ.showExplanation,
				lastSubmitted: data.latestAttempt?.userInputMCQ,
			};
		} else if (+attemptQuestionData.type === QuestionType.MQ && data.question.questionTypeCoding) {
			const {
				testCase, executionType, multipleTestCases,
			} = data.question.questionTypeCoding;

			attemptQuestionData.questionTypeMQ = {
				executionType,
				multipleTestCases,
				testCases: testCase.map((el) => ({
					_id: el._id,
					attemptInMultiLine: el.attemptInMultiLine,
					sampleTest: !!el.sampleTest,
					expectedOutput: el.codeprogexpectedoutput,
					input: el.codeproginputparams,
					score: el.scoreip,
				})),
				lastSubmitted: data.latestAttempt?.userOutputCoding,
			};
		} else if (
			+attemptQuestionData.type === QuestionType.Coding && data.question.questionTypeCoding
		) {
			const {
				testCase, executionType, multipleTestCases, codeproglang,
			} = data.question.questionTypeCoding;

			attemptQuestionData.questionTypeCoding = {
				showHead: data.question.showHead,
				showTail: data.question.showTail,
				showCustomInput: data.question.showCustomInput,
				executionType,
				multipleTestCases,
				testCases: testCase.map((el) => ({
					_id: el._id,
					attemptInMultiLine: el.attemptInMultiLine,
					sampleTest: !!el.sampleTest,
					expectedOutput: el.codeprogexpectedoutput,
					input: el.codeproginputparams,
					score: el.scoreip,
					status: TestCaseStatus.NotExecuted,
					userOutput: '',
				})),
				codeLanguages: codeproglang.map((el) => ({
					_id: el._id,
					defaultCompileResult: el.defaultCompileResult,
					defaultTrimmedCode: el.defaultTrimmedCode,
					language: el.language,
					codeComponents: el.codeComponents,
				})),
				lastSubmitted: data.latestAttempt ? {
					language: data.latestAttempt.userLanguage,
					code: data.latestAttempt.userProgram?.replace(/^"|"$/g, '').replace(/\\n/g, '\n').replace(/\\"/g, '"'),
				} : undefined,
			};
		} else if (
			+attemptQuestionData.type === QuestionType.Web && data.question.questionTypeWeb
		) {
			attemptQuestionData.questionTypeWeb = {
				...data.question.questionTypeWeb,
				testCases: data.question.questionTypeWeb.testCase
					? data.question.questionTypeWeb.testCase.map((el) => ({
						_id: el._id,
						sampleTest: el.sampleTest,
						description: el.description,
						evaluator: el.evaluator,
						score: el.scoreip,
						status: TestCaseStatus.NotExecuted,
					})) : [],
				lastSubmitted: data.latestAttempt ? {
					html: data.latestAttempt.html,
					css: data.latestAttempt.css,
					js: data.latestAttempt.js,
				} : undefined,
			};
		} else if (+attemptQuestionData.type === QuestionType.Subjective) {
			attemptQuestionData.questionTypeSubjective = {
				isFileUpload: data?.question?.questionTypeSubjective?.isFileUpload || false,
				lastSubmitted: data.latestAttempt?.userInputSubjective,
			};
		}

		this.emit('attempt-question-data-received', attemptQuestionData);
	};

	submitQuestion = async (
		quizId: string, questionId: string, requestPayload: any,
	): Promise<void> => {
		const formData = new FormData();
		Object.entries(requestPayload).forEach((entry) => {
			if (entry[1] instanceof Array) {
				entry[1].forEach((val: any) => formData.append(`${entry[0]}[]`, val));
			} else {
				formData.append(entry[0], entry[1] as any);
			}
		});

		// const response = await this._mainAPI.post(
		// 	`/attempt/attemptquestion/quiz/${quizId}/${questionId}`,
		// 	formData,
		// 	{
		// 		headers: {
		// 			'content-type': 'multipart/form-data',
		// 		},
		// 	},
		// );

		const response = await this.mainApiRequestSend({
			method: 'POST',
			url: `/attempt/attemptquestion/quiz/${quizId}/${questionId}`,
			payload: formData,
			headers: {
				'content-type': 'multipart/form-data',
			},
		});

		const data = response?.data as any;
		if (data.error) {
			throw new Error(data.error);
		}

		this.emit('question-submitted', questionId);
	};

	submitQuizSegment = async (
		quizId: string, questionId: string, segmentIndex: number,
	): Promise<void> => {
		// const response = await this._mainAPI.post(
		// 	`/attempt/submitsegment/quiz/${quizId}/${questionId}/${segmentIndex}`,
		// );
		const response = await this.mainApiRequestSend({
			method: 'POST',
			url: `/attempt/submitsegment/quiz/${quizId}/${questionId}/${segmentIndex}`,
		});

		const data = response.data as any;
		if (data.error) {
			throw new Error(data.error);
		}

		this.emit('quiz-segment-submitted', segmentIndex);
	};

	submitQuiz = async (
		quizId: string, isAutoSubmit?: boolean, isForceSubmit?:boolean,
	): Promise<string | void> => {
		// const response = await this._mainAPI.get(`/attempt/submitQuiz/${quizId}`, {
		// 	params: {
		// 		isAutoSubmit: isAutoSubmit ? 1 : 0,
		// 	},
		// });
		const response = await this.mainApiRequestSend({
			method: 'GET',
			url: `/attempt/submitQuiz/${quizId}`,
			params: {
				isAutoSubmit: isAutoSubmit ? 1 : 0,
				isForceSubmit: isForceSubmit ? 1 : 0,
			},
		});

		const data = response.data as any;
		if (data.error) {
			throw new Error(data.error);
		}

		this.emit('quiz-submitted');

		return data.redirectLink;
	};

	getRemainingTime = async (quizId: string): Promise<void> => {
		// const response = await this._mainAPI.get(`/test/remainingTime/${quizId}`);
		const response = await this.mainApiRequestSend({
			method: 'GET',
			url: `/test/remainingTime/${quizId}`,
		});

		const data = response.data as QuizRemainingTimeResponse;
		if (data.error) {
			throw new Error(data.error);
		}

		const time: number = +data.remainingTime + +data.extraTime;

		if (data.quizUpdatedAt) {
			this.emit('quiz-updated', data.quizUpdatedAt);
		}

		this.emit('quiz-remaining-time-updated', time);
		this.emit('quiz-suspicious-updated', data);
	};

	compileCode = async (compileRequest: CodeCompilationRequest): Promise<void> => {
		const { _socket } = this;

		return new Promise((resolve, reject) => {
			let resolved = false;

			if (!navigator.onLine) {
				resolved = true;

				logger.error('No internet for compile');
				reject(new Error('Please check your internet connection.'));
			}

			const timer = setTimeout(() => {
				resolved = true;

				logger.error('timeout waiting for compile');
				reject(new Error('timed out'));
			}, 10000);

			_socket.emit('compile', compileRequest, (error) => {
				if (resolved) return;

				resolved = true;
				clearTimeout(timer);

				if (error) {
					logger.error(`compile error: ${error}`);
					reject(new Error('failed to compile'));
				} else {
					resolve();

					if (compileRequest.questionSubmission) {
						this.emit('question-submitted', compileRequest.questionId);
					}
				}
			});
		});
	};

	sendCheatingDetails = async (cheatingType: number, tabSwitchType?: number): Promise<void> => {
		const { _socket, _sessionData } = this;

		if (_sessionData && this.connected) {
			_socket.emit('notification', {
				sid: _sessionData.sessionId,
				userId: _sessionData.userId,
				cheatingType,
				tabSwitchType,
			});
		}
	};

	sendAiProctoringResult = async (type: number, data) => {
		const { _socket, _sessionData } = this;
		if (_sessionData && this.connected) {
			_socket.emit('notification', {
				sid: _sessionData.sessionId,
				userId: _sessionData.userId,
				cheatingType: type,
				data,
			});
		}
	};

	sendImageDataSync = async (
		userId: string, quizId: string, sessionId: string, imageData: unknown,
	): Promise<string> => {
		try {
			const result = await this._staticStorageAPI.post('/uploadSync', {
				userId,
				quizId,
				sId: sessionId,
				data: imageData,
			});
			if (result.data.error) {
				throw new Error(result.data.error);
			}
			console.log('Result of upload', result.data);
			return (result.data?.data?.url ?? '');
		} catch (error) {
			console.log(error);
			throw error;
		}
	};

	sendImageData = async (
		userId: string, quizId: string, sessionId: string, imageData: unknown,
	): Promise<void> => {
		try {
			await this._staticStorageAPI.post('/upload', {
				userId,
				quizId,
				sId: sessionId,
				data: imageData,
			});
		} catch (e: any) {
			logger.error('error while sending invigilator data ==> ', e);
		}
	};

	getServerTime = async (): Promise<void> => {
		const response = await this.mainApiRequestSend({
			method: 'GET',
			url: '/api/getServerTime',
		});

		if (response.error) {
			throw new Error(response.error);
		}
		this.emit('server-time', response.data.Date);
	};

	downloadApp = async (platform?:string): Promise<void> => {
		const url = `${this._quizServerUrl}/app/download${(platform) ? `/${platform}` : ''}`;
		window.open(url, '_blank');
	};

	forgotPassword = async (data: forgotPasswordPayload): Promise<void> => {
		const response = await this.mainApiRequestSend({
			method: 'POST',
			url: '/api/forgot',
		});
		if (response.error) {
			throw new Error(response.error);
		}
		return response?.data;
	};

	redirectToQuizClient = (path: string): void => {
		window.location.href = `${this._quizServerUrl}${path}`;
	};

	validateToken = async (token: string): Promise<boolean> => {
		const response = await this.mainApiRequestSend({
			method: 'GET',
			url: `/test/validateToken/${token}`,
		});
		if (response.data.error) {
			throw new Error(response.data.error);
		}
		return response?.data?.data;
	};

	getLiveStreamRoom = async (): Promise<string> => {
		const response = await this.mainApiRequestSend({
			method: 'GET',
			url: '/quiz-api/liveStreamRoom',
		});
		if (response.data.error) {
			throw new Error(response.data.error);
		}
		return response?.data?.roomName;
	};

	getJitsiCredentials = async (): Promise<JitsiCredentials> => {
		const response = await this.mainApiRequestSend({
			method: 'GET',
			url: '/quiz-api/jitsiCredentials',
		});
		if (response.data.error) {
			throw new Error(response.data.error);
		}
		return response?.data?.credentials;
	};

	updateJitsiCredentials = async (data: NonNullable<JitsiCredentials>) => {
		this._socket.emit('update_jitsi_credentials', data);
	};
}
