// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".font-change-slider_slider__3Cn3m {\n  -webkit-appearance: none;\n  width: 100%;\n  height: 3px;\n  background: #d3d3d3;\n  outline: none;\n  opacity: 0.7;\n  transition: opacity .2s;\n}\n\n.font-change-slider_slider__3Cn3m:hover {\n  opacity: 1;\n}\n\n.font-change-slider_slider__3Cn3m::-webkit-slider-thumb {\n  appearance: none;\n  width:10px;\n  height: 10px;\n  border-radius: 50%;\n  background-color: var(--primary-color);\n  cursor: pointer;\n}\n\n.font-change-slider_slider__3Cn3m::-moz-range-thumb {\n  width: 0.3vh;\n  height: 0.3vh;\n  background: #04AA6D;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://src/components/font-change-slider/font-change-slider.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,WAAW;EACX,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,YAAY;EAEZ,uBAAuB;AACzB;;AAEA;EACE,UAAU;AACZ;;AAEA;EAEE,gBAAgB;EAChB,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,sCAAsC;EACtC,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".slider {\n  -webkit-appearance: none;\n  width: 100%;\n  height: 3px;\n  background: #d3d3d3;\n  outline: none;\n  opacity: 0.7;\n  -webkit-transition: .2s;\n  transition: opacity .2s;\n}\n\n.slider:hover {\n  opacity: 1;\n}\n\n.slider::-webkit-slider-thumb {\n  -webkit-appearance: none;\n  appearance: none;\n  width:10px;\n  height: 10px;\n  border-radius: 50%;\n  background-color: var(--primary-color);\n  cursor: pointer;\n}\n\n.slider::-moz-range-thumb {\n  width: 0.3vh;\n  height: 0.3vh;\n  background: #04AA6D;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slider": "font-change-slider_slider__3Cn3m"
};
export default ___CSS_LOADER_EXPORT___;
