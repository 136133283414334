import React from 'react';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { CheckCircleFilled } from '@ant-design/icons';

import { QuestionStatus } from '../../config';

import { ReactComponent as LockIcon } from '../../assets/icons/lock-icon.svg';
import { ReactComponent as CheckPrimary } from '../../assets/icons/check-primary.svg';

import './attempt-sidebar-segment.scoped.css';

interface AttemptSidebarSegmentProps {
	quizName: string
	segmentIndex: number
	segmentData: QuizDashboardSegmentData
	isLocked: boolean
	isSubmitted: boolean
	currentQuestionId: string
	style?: React.CSSProperties
}

// eslint-disable-next-line max-len
export const AttemptSidebarSegment: React.FunctionComponent<AttemptSidebarSegmentProps> = (props) => {
	const {
		quizName, segmentData, segmentIndex, isLocked, currentQuestionId, style, isSubmitted,
	} = props;

	return (
		<div className="sidebar-segment-container" style={style}>
			{
				isLocked && (
					<Tooltip
						title="Section locked, please submit previous section to unlock."
						placement="right"
					>
						<div className="icon-overlay">
							<LockIcon style={{ height: 24 }} />
						</div>
					</Tooltip>
				)
			}
			{
				isSubmitted && (
					<Tooltip
						title="Section has already been submitted."
						placement="right"
					>
						<div className="icon-overlay">
							<CheckCircleFilled style={{ fontSize: 24, color: '#525252' }} />
						</div>
					</Tooltip>
				)
			}
			<div className="segment-title-container">
				<span>
					Sec-
					{String.fromCharCode('A'.charCodeAt(0) + segmentIndex)}
				</span>
			</div>
			<div className="segment-content">
				{
					segmentData.questions.map((q, i) => (
						<Tooltip
							key={q._id}
							title={q.title}
							placement="right"
						>
							<Link
								className={currentQuestionId === q._id ? 'active' : ''}
								to={`/test/${quizName}/attempt/${q._id}`}
							>
								<span>
									{i + 1}
								</span>
								<span>
									{
										(() => {
											if (q.status === QuestionStatus.Submitted) {
												return <CheckPrimary style={{ height: 12, width: 12 }} />;
											}
											return null;
										})()
									}
								</span>
							</Link>
						</Tooltip>
					))
				}
			</div>
		</div>
	);
};
