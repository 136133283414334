import React, {
	useState,
	useCallback, useEffect, useRef, useMemo,
} from 'react';
import Draggable from 'react-draggable';
import { getRandomInteger } from '../../libs/utils';
import createAiProctoring from '../../libs/object-dection/index';
import './invigilator-cam.scoped.css';
import { AIProctor } from '../../libs/object-dection/object-detection';
import { } from '../../store/ai-proctor';

interface InvigilatorCamProps {
	className?: string
	style?: React.CSSProperties
	stream: MediaStream | null
	videoElement: HTMLVideoElement | null
	setVideoElement: (videoElement: HTMLVideoElement | null) => void
	onUpload: (data: unknown) => Promise<void>
}

const camHeight = 90;
const camWidth = 120;

export const InvigilatorCam: React.FunctionComponent<InvigilatorCamProps> = (props) => {
	const {
		style, className, stream, onUpload, videoElement, setVideoElement,
	} = props;

	const canvasRef: React.Ref<HTMLCanvasElement> = useRef(null);
	const [windowDimensions, setWindowDimensions] = useState<any>({
		width: window.innerWidth, height: window.innerHeight,
	});

	// const [aiProctor, setAIProcotring] = useState<AIProctor | null>(null);

	// useEffect(() => {
	// 	if (!aiProctor && feed) {
	// 		console.log('Working i guess');
	// 		const result = createAiProctoring({
	// 			frequency: 60,
	// 			mediaInput: feed,
	// 			webWorker: true,
	// 		});
	// 		result.on('result', (data) => {
	// 			if (data.faceDetection) {
	// 				if (data.faceDetection.faceLandmarks.length) {
	// 					console.log('One Face Detected');
	// 				}
	// 			}
	// 			if (data.objectDetection?.detections.length) {
	// 				console.log('Some Objects Detected');
	// 			}
	// 			console.log('Some Prediction Here', data);
	// 		});
	// 		result.on('initComplete', () => {
	// 			console.log('Init Complete');
	// 		});
	// 		result.on('error', (err) => {
	// 			console.log('Something went wrong', err);
	// 		});
	// 		setAIProcotring(result);
	// 	}
	// }, [feed, aiProctor, setAIProcotring]);

	const capture = useCallback(async () => {
		if (canvasRef.current && videoElement) {
			const canvas = canvasRef.current;
			const context = canvas.getContext('2d');
			canvas.height = camHeight * 2;
			canvas.width = camWidth * 2;
			context?.drawImage(videoElement, 0, 0, camWidth * 2, camHeight * 2);
			return canvas.toDataURL('image/png');
		}

		return null;
	}, [videoElement]);

	useEffect(() => {
		const intervalId = setInterval(async () => {
			const imageData = await capture();
			if (imageData) {
				await onUpload(imageData);
			}
		}, getRandomInteger(60, 300) * 1000);

		return () => clearInterval(intervalId);
	}, [capture, onUpload]);

	useEffect(() => {
		if (videoElement && stream) {
			videoElement.srcObject = stream;
			videoElement.controls = false;
			videoElement.addEventListener('loadeddata', () => {
				videoElement.play();
			});

			videoElement.style.display = 'unset';
		}
	}, [videoElement, stream]);

	return (
		<Draggable
			bounds={{
				top: -50,
				left: -(windowDimensions.width - 140),
				right: 0,
				bottom: (windowDimensions.height - 180),
			}}
		>
			<div className={`invigilator-cam-widget ${className}`} style={style}>
				<canvas ref={canvasRef} style={{ display: 'none' }} />
				<video
					disablePictureInPicture
					muted
					ref={setVideoElement}
					className="feed"
					style={{
						height: camHeight,
						width: camWidth,
					}}
				/>
			</div>
		</Draggable>
	);
};
