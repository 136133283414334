/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';
import {
	Button, Input, Form,
} from 'antd';

import './login-form.scoped.css';
import { APP_CONFIG } from '../../config';

interface LoginFormValues {
	email: string
	password: string
	quizCode: string
}

interface LoginFormProps {
	onLogin: (email: string, password: string, quizCode: string) => Promise<void>
	forgotPassword: () => void
}

export const LoginForm: React.FunctionComponent<LoginFormProps> = (props) => {
	const { onLogin: loginUser, forgotPassword } = props;

	const [loggingIn, setLoggingIn] = useState<boolean>(false);
	const handleLoginFormSubmit = useCallback(async (values: LoginFormValues) => {
		try {
			setLoggingIn(true);
			await loginUser(values.email.toLowerCase(), values.password, values.quizCode);
		} catch (e) {
			setLoggingIn(false);
		}
	}, [loginUser]);

	return (
		<div className="sign-in-form-container">
			<Form
				layout="vertical"
				onFinish={handleLoginFormSubmit}
			>
				{APP_CONFIG.isChitkara ? (
					<Form.Item
						label="email or rollnumber *"
						name="email"
						rules={[{
							required: true, whitespace: true, message: 'Please enter email or roll number!',
						}, {
							type: 'string', message: 'Please enter valid email or roll number',
						}]}
						normalize={(value, prevVal, prevVals) => value.trim()}
					>
						<Input />
					</Form.Item>
				)
					: (
						<Form.Item
							label="email *"
							name="email"
							rules={[{
								required: true, whitespace: true, message: 'Please enter email!',
							}, {
								type: 'email', message: 'Please enter valid email',
							}]}
							normalize={(value, prevVal, prevVals) => value.trim()}
						>
							<Input />
						</Form.Item>
					)}

				<Form.Item
					label="password *"
					name="password"
					rules={[
						{ required: true, whitespace: true, message: 'Please enter password!' },
						{ min: 8, max: 32, message: 'Your password must be at least 8 characters and max, 32 characters long.' },
					]}
				>
					<Input.Password />
				</Form.Item>

				<Form.Item
					label="test code *"
					name="quizCode"
					rules={[{ required: true, whitespace: true, message: 'Please enter the test code!' },
					{
						pattern: new RegExp(/^\d+$/),
						message: 'Please enter only numeric values',
					}]}
				>
					<Input />
				</Form.Item>

				<Form.Item>
					<div className="form-actions">
						<Button
							type="primary"
							size="large"
							htmlType="submit"
							loading={loggingIn}
						>
							sign in
						</Button>
						<div>
							<Button onClick={forgotPassword} type="link">forgot password?</Button>
						</div>
					</div>
				</Form.Item>
			</Form>
		</div>
	);
};
