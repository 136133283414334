import { EventEmitter } from 'events';
import TypedEventEmitter from 'typed-emitter';
import { FaceLandmarkerResult, ObjectDetectorResult } from '../../@types/mediapipe';

export interface DetectionResult {
		imageBitMap: ImageBitmap | null,
		faceDetection: FaceLandmarkerResult | null,
		objectDetection: ObjectDetectorResult | null,
}

export enum State {
	'Start' = 1,
	'Stop' = 2,
}

export interface SupportedEvents {
	'result': (result: DetectionResult) => void,
	'initComplete': () => void,
	'error': (error: Error) => void,
}

export interface SessionData {
	'userId': string,
	'quizId': string,
	'sessionId': string,
}

export class AIProctor extends (EventEmitter as new () => TypedEventEmitter<SupportedEvents>) {
	private videoElementRef: HTMLVideoElement;

	private state: State;

	private detectionInterval: NodeJS.Timer | null;

	private frequency: number;

	private useWebWorker: boolean;

	private initComplete: boolean;

	private canvas: OffscreenCanvas;

	private webWorker: null | Worker;

	private imageQueue: ImageBitmap[] = [];

	private sessionData: SessionData | null;

	constructor(videoElementRef: HTMLVideoElement, config: {
		frequency: number,
		webWorker: boolean,
	}) {
		super();
		this.useWebWorker = config.webWorker;
		this.webWorker = null;
		this.videoElementRef = videoElementRef;
		this.frequency = 3000;
		this.state = State.Stop;
		this.detectionInterval = null;
		this.initComplete = false;
		this.canvas = new OffscreenCanvas(1080, 720);
		this.sessionData = null;
		this.init();
	}

	private parseWebWorkerResult(data) {
		try {
			if (typeof data === 'string') {
				if (data === 'init-complete') {
					this.initalComplete();
					return;
				}
				try {
					const parsedData = JSON.parse(data);
					if ('detectionResult' in parsedData) {
						parsedData.detectionResult.imageBitMap = this.imageQueue.shift();
						this.emit('result', parsedData.detectionResult);
					}
				} catch (error) {
					console.error(error);
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	async init() {
		try {
			const worker = new Worker('/worker/object-detection-worker.js');
			worker.onmessage = (message) => {
				this.parseWebWorkerResult(message.data);
			};
			worker.onerror = (ev) => {
				console.log('Worker Error Message', ev);
			};
			worker.onmessageerror = (ev) => {
				console.log(ev.data);
			};
			this.webWorker = worker;
		} catch (error) {
			console.log('ERROR IN WEBWORKER', error);
			if (error instanceof Error) {
				this.emit('error', error);
			}
			if (typeof error === 'string') {
				this.emit('error', new Error(error));
			}
		}
	}

	private initalComplete() {
		this.initComplete = true;
		this.setState(State.Start);
	}

	setState(state: State) {
		if (this.detectionInterval) {
			clearInterval(this.detectionInterval);
		}
		if (state === State.Start) {
			console.log('Starting Detection');
			this.detectionInterval = setInterval(() => {
				this.detect();
			}, this.frequency);
		} else {
			this.state = State.Stop;
			if (this.detectionInterval) {
				clearInterval(this.detectionInterval);
			}
		}
	}

	private detectWithWebWorker(imageToSend: ImageBitmap) {
		const image = structuredClone(imageToSend);
		if (this.webWorker) {
			this.webWorker.postMessage(image, [image]);
		}
	}

	private detectWithoutWebWorker(image: ImageBitmap) {
		console.log(this.webWorker);
		throw new Error('currently does not support detection without webworker');
	}

	detect() {
		if (!this.initComplete) {
			return;
		}
		const ctx = this.canvas.getContext('2d');
		if (!ctx) {
			return;
		}
		if ('drawImage' in ctx) {
			ctx.drawImage(this.videoElementRef, 0, 0, 1080, 720);
		}
		const bitmap = this.canvas.transferToImageBitmap();
		this.imageQueue.push(bitmap);
		if (this.useWebWorker) {
			this.detectWithWebWorker(bitmap);
			return;
		}
		this.detectWithoutWebWorker(bitmap);
	}
}
