import {
	Button, Divider, Form, Radio, Space,
} from 'antd';
import React, { useCallback, useState } from 'react';

import './attempt-mcq.scoped.css';

interface MCQFormValues {
	answer: number
}

interface AttemptMCQProps {
	questionData: AttemptQuestionData
	submitting: boolean
	onSubmit: (data: any) => Promise<void>
	setAttempt: (attemptData: unknown) => void
	getAttempt: () => any | null
}

export const AttemptMCQ: React.FunctionComponent<AttemptMCQProps> = (props) => {
	const { questionData, submitting: isSubmitting, onSubmit } = props;
	const [form] = Form.useForm();

	const [answerError, setAnswerError] = useState<string | undefined>();

	const handleMCQFormSubmit = useCallback(async (values: MCQFormValues) => {
		await onSubmit({
			inputMCQ: `chkOpt${values.answer}`,
			chk: `chkOpt${values.answer}`,
		});
	}, [onSubmit]);

	const handleMCQFormSubmitFailed = useCallback(async ({ values, errorFields, outOfDate }) => {
		if (errorFields[0].errors[0]) {
			setAnswerError(errorFields[0].errors[0]);
		}
	}, []);

	return (
		<div className="attempt-mcq-container">
			<div>
				<span className="info-text">Choose any one</span>
				<Divider style={{ margin: '0.5rem 0 1.5rem 0', width: '50%', minWidth: 'unset' }} />
				<div>
					<Form
						form={form}
						onFinish={handleMCQFormSubmit}
						onFinishFailed={handleMCQFormSubmitFailed}
						onFieldsChange={() => setAnswerError(undefined)}
					>
						<Form.Item
							name="answer"
							rules={[{ required: true, message: 'Please select an option!' }]}
							initialValue={(
								typeof questionData.questionTypeMCQ?.lastSubmitted === 'number'
									? questionData.questionTypeMCQ.lastSubmitted + 1
									: (questionData.questionTypeMCQ?.lastSubmitted
										&& parseInt(questionData.questionTypeMCQ?.lastSubmitted, 10))
							)}
						>
							<Radio.Group
								disabled={isSubmitting}
							>
								<Space direction="vertical">
									{
										questionData.questionTypeMCQ?.options?.map((option, index) => (
											<Radio
												key={option}
												value={index + 1}
											>
												{option}
											</Radio>
										))
									}
								</Space>
							</Radio.Group>
						</Form.Item>
						<Form.Item>
							<Button
								type="text"
								size="small"
								onClick={() => form.setFieldsValue({
									answer: null,
								})}
								disabled={isSubmitting}
							>
								Clear selection
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
			<div className="submit-mcq-container">
				<span className="error-message">{answerError}</span>
				<Button
					type="primary"
					size="large"
					onClick={() => form.submit()}
					loading={isSubmitting}
				>
					submit
				</Button>
			</div>
		</div>
	);
};
