import axios, { AxiosInstance, Method } from 'axios';
import { logger } from '../../libs/utils/logger';
import {
	APP_CONFIG,
} from '../../config';

interface CQLoginClientOptions {
	loginServiceURL: string
}

interface mainApiRequestParams {
	method: Method;
	url: string, params?: any;
	payload?: any;
	config?: any;
	headers?: any;
}

export class CQLoginClient {
	private _options: CQLoginClientOptions;

	private _loginServiceURL: string;

	private _mainAPI: AxiosInstance;

	constructor(options: CQLoginClientOptions) {
		const { loginServiceURL } = options;
		const mainAPI = axios.create({
			baseURL: loginServiceURL,
			responseType: 'json',
			withCredentials: false,
		});

		this._mainAPI = mainAPI;
		this._options = options;
		this._loginServiceURL = loginServiceURL;
	}

	mainApiRequestSend = async (
		{
			method, url, params, payload, config, headers,
		}: mainApiRequestParams,
	): Promise<any> => {
		let isLogout = false;
		try {
			const response = await this._mainAPI({
				method,
				url,
				data: payload,
				params,
				...config,
				headers,
			});

			if (response.data.error && response.status === 401) {
				throw new Error(response.data.error);
			}

			return response;
		} catch (error: any) {
			logger.log(error?.response);
			if (error?.response?.data?.error) {
				if (error?.response.status === 401) {
					isLogout = true;
				}
				throw new Error(error.response.data.error);
			}
			if (error.message.indexOf('status code 401') !== -1) {
				isLogout = true;
			} else if (error.message.indexOf('Network Error') !== -1) {
				throw new Error('Please check your internet connection.');
			}
			throw new Error(error.message);
			return {
				data: error,
			};
		} finally {
			if (isLogout) {
				// need to change soon. Because we need to fix this issue soon that's why we did it.
				// message.error('Session Expired');
				// await (new Promise((res, rej) => {
				// 	setTimeout(res, 1000);
				// }));
				localStorage.setItem('forceSessionExpired', JSON.stringify(Date.now()));
				window.location.reload();
				await (new Promise((res) => {
					setTimeout(res, 3000);
				}));
			}
		}
	};

	forgotPassword = async (data: forgotPasswordPayload): Promise<any> => {
		const response = await this.mainApiRequestSend({
			method: 'POST',
			url: '/forgot',
			payload: {
				email: data.email,
				'g-recaptcha-response': data.captia,
			},
		});
		if (response.error ?? response.data.error) {
			throw new Error(response.error ?? response.data.error);
		}
		return response?.data;
	};
}
