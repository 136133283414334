import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Button, Divider, Input, message, Form, Radio, Checkbox, Select,
} from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import { useAppStore, useQuizStore, useSessionStore } from '../../../store';

import './quiz-details.scoped.css';
import { logger } from '../../../libs/utils/logger';
import { APP_CONFIG, QuizUserDetailsInputType } from '../../../config';

const appStoreSelector = (state: AppState) => ({
	setAppLoading: state.setAppLoading,
});

const sessionStoreSelector = (state: SessionState) => ({
	tryTest: state.sessionData?.tryTest,
});

const quizStoreSelector = (state: QuizState) => ({
	quizData: state.quizData,
	startQuiz: state.startQuiz,
	serverTime: state.serverTime,
});

interface QuizDetailsScreenProps {
	quizName: string
}

export const QuizDetailsScreen: React.FunctionComponent<QuizDetailsScreenProps> = () => {
	const { setAppLoading } = useAppStore(appStoreSelector);
	const { quizData, startQuiz, serverTime } = useQuizStore(quizStoreSelector);
	const { tryTest } = useSessionStore(sessionStoreSelector);
	const [startingQuiz, setStartingQuiz] = useState<boolean>(false);
	const [isFieldsPresent, setIdFieldPresent] = useState<boolean>(false);

	const [form] = Form.useForm();

	const history = useHistory();

	const logoutUser = useCallback(() => {
		try {
			if (!navigator.onLine) {
				throw new Error('Please check your internet connection.');
			}
			history.push({
				state: { referrer: '' },
			});
			window.location.replace(`${APP_CONFIG.QuizServerURL}/logout`);
		} catch (error: any) {
			message.error(error.message);
		}
	}, [history]);

	// eslint-disable-next-line consistent-return
	const handleStartQuiz = useCallback(async () => {
		try {
			console.log('In the handler');
			if (!tryTest
				&& (
					quizData?.endTime
					&& (serverTime ?? Date.now()) > new Date(quizData?.endTime).getTime())
			) {
				return logoutUser();
			}
			// setAppLoading(true, 'starting test...');
			setAppLoading(true);
			setStartingQuiz(true);

			await startQuiz();
		} catch (e: any) {
			logger.error(e);

			setStartingQuiz(false);
			message.error(e.message);
		} finally {
			setAppLoading(false);
		}
	}, [tryTest, quizData?.endTime, serverTime, setAppLoading, startQuiz, logoutUser]);

	// eslint-disable-next-line consistent-return
	const handleUserQuizDetailsFormSubmit = useCallback(async (values: any) => {
		try {
			const data = Object.entries(values);

			const quizUserDetails = data.map((el) => {
				const [key, value] = el;

				const detailData = quizData?.quizUserDetails?.find((detail) => detail.fieldName === key);

				return {
					fieldLabel: detailData?.fieldLabel,
					fieldValue: value instanceof Array ? value.join(', ') : value,
				};
			});

			// setAppLoading(true, 'starting test...');
			if (!tryTest
				&& (
					quizData?.endTime
					&& (serverTime ?? Date.now()) > new Date(quizData?.endTime).getTime())
			) {
				return logoutUser();
			}

			setAppLoading(true);
			setStartingQuiz(true);

			await startQuiz(quizUserDetails);
		} catch (e: any) {
			logger.error(e);

			setStartingQuiz(false);
			message.error(e.message);
		} finally {
			setAppLoading(false);
		}
	}, [
		tryTest,
		quizData?.endTime,
		quizData?.quizUserDetails,
		serverTime, setAppLoading,
		startQuiz,
		logoutUser,
	]);

	useEffect(() => {
		let isFieldP = false;
		quizData?.quizUserDetails?.forEach((el) => {
			if (el.fieldIsSelected) {
				isFieldP = true;
			}
		});
		setIdFieldPresent(isFieldP);
	}, [quizData?.quizUserDetails]);

	return (
		<div className="quiz-details-container">
			<div className="quiz-specs-container">
				<div>
					<div className="quiz-info-text">
						<span>Total Questions</span>
						&nbsp;&nbsp;&nbsp;
						<span style={{ color: 'var(--primary-color)' }}>{quizData?.questions}</span>
					</div>
					<div className="quiz-info-text">
						<span>Total Time</span>
						&nbsp;&nbsp;&nbsp;
						<span style={{ color: 'var(--primary-color)' }}>
							{quizData?.quizTime}
							{' '}
							mins
						</span>
					</div>
				</div>
				{
					quizData?.instructions
					&& quizData.instructions !== '<p><br></p>'
					&& quizData.instructions.replace(/^<p>|<\/p>$/g, '').trim()
					&& (
						<>
							<Divider />
							<div className="quiz-instructions-container">
								<span>Instructions</span>
								<div
									className="quiz-instructions ql-editor"
									// eslint-disable-next-line react/no-danger
									dangerouslySetInnerHTML={{
										__html: quizData.instructions as string,
									}}
								/>
							</div>
						</>
					)
				}
			</div>

			{
				quizData?.quizUserDetails && quizData.quizUserDetails.length && isFieldsPresent
					? (
						<div className="forms-container">
							<div className="forms-card">
								<div className="forms-card-header">
									<span>required</span>
								</div>
								<div className="user-quiz-details-form-container">
									<Form
										form={form}
										layout="vertical"
										scrollToFirstError
										onFinish={handleUserQuizDetailsFormSubmit}
									>
										{
											quizData.quizUserDetails?.map((el) => (
												el?.fieldIsSelected
												&& (
													<Form.Item
														key={el._id}
														label={`${el.fieldLabel} *`}
														name={el.fieldName}
														rules={[{
															required: true,
															whitespace: true,
															message: (() => {
																if (el.fieldType === QuizUserDetailsInputType.Select) {
																	return `Please select your ${el.fieldLabel}!`;
																} if (el.fieldType === QuizUserDetailsInputType.Checkbox) {
																	return `Please select value(s) for ${el.fieldLabel}!`;
																} if (el.fieldType === QuizUserDetailsInputType.Radio) {
																	return `Please select a value for ${el.fieldLabel}!`;
																}

																return `Please enter your ${el.fieldLabel}!`;
															})(),
															transform: el.fieldType === QuizUserDetailsInputType.Checkbox
																? (val: string[]) => val?.join(',')
																: undefined,
														},
														{
															pattern: new RegExp(/^[a-z0-9&/.() @,_-]+$/i),
															message: el.fieldType === QuizUserDetailsInputType.TextInput ? 'Only alphanumeric and special character(&/.() @,_-) allowed' : '',
														},
														]}
													>
														{
															(() => {
																if (el.fieldType === QuizUserDetailsInputType.TextInput) {
																	return <Input />;
																}

																if (el.fieldType === QuizUserDetailsInputType.Select) {
																	return (
																		<Select
																			suffixIcon={<CaretDownFilled style={{ color: '#818181' }} />}
																			options={el.fieldOptions?.map((option) => ({
																				label: option, value: option,
																			}))}
																			placeholder="Select a value..."
																			getPopupContainer={(trigger) => trigger.parentElement}
																		/>
																	);
																}

																if (el.fieldType === QuizUserDetailsInputType.Checkbox) {
																	return (
																		<Checkbox.Group options={
																			el.fieldOptions?.map((option) => ({
																				label: option, value: option,
																			}))
																		}
																		/>
																	);
																}

																if (el.fieldType === QuizUserDetailsInputType.Radio) {
																	return (
																		<Radio.Group options={el.fieldOptions?.map((option) => ({
																			label: option, value: option,
																		}))}
																		/>
																	);
																}

																return null;
															})()
														}
													</Form.Item>
												)
											))
										}
									</Form>
									<div className="form-actions">
										<Button
											type="primary"
											size="large"
											loading={startingQuiz}
											onClick={form.submit}
										>
											start test
										</Button>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="start-quiz-container">
							<Button
								type="primary"
								size="large"
								loading={startingQuiz}
								onClick={handleStartQuiz}
							>
								start test
							</Button>
						</div>
					)
			}

			{/* <div className="cq-watermark">
				<span>code</span>
				<span>quotient</span>
			</div> */}
		</div>
	);
};
