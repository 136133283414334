import React, {
	useCallback, useEffect, useRef,
} from 'react';
import AceEditor from 'react-ace';

import './editor.scoped.css';

import 'ace-builds/webpack-resolver';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/src-min-noconflict/ext-emmet';
import { message } from 'antd';
import { useQuizStore } from '../../store';

interface EditorProps {
	name: string
	mode?: string
	value?: string
	defaultValue?: string
	showLineNumbers?: boolean
	minLines?: number
	maxLines?: number
	readOnly?: boolean
	disableCopyPaste?: boolean
	className?: string
	style?: React.CSSProperties
	notifyOnPaste?: boolean
	editorRefMain?: any
	pasteHandler?: any
	initalFocus?: any
	onChange?: (value: string) => void
	sendPasteNotification?: () => void
}

const quizStoreSelector = (state: QuizState) => ({
	tabSwitch: state.tabSwitchCountChangeLocal,
});

interface pasteNotifcationIdInterface {
	id: NodeJS.Timeout | null;
}

const editorEvents = ['paste', 'dragenter', 'dragover', 'dragend', 'dragstart', 'dragleave', 'drop', 'contextmenu'];
const pasteNotificationEvents = ['paste', 'drop'];
const stop = (e: any) => {
	e.stopPropagation();
	e.preventDefault();
};

export const Editor: React.FunctionComponent<EditorProps> = (props) => {
	const {
		name, mode, value, defaultValue, className, notifyOnPaste,
		disableCopyPaste, style, showLineNumbers, minLines, maxLines, readOnly,
		editorRefMain, onChange, pasteHandler, initalFocus,
		sendPasteNotification,
	} = props;

	const { tabSwitch } = useQuizStore(quizStoreSelector);
	const editorRef: React.Ref<AceEditor> | null = useRef(null);

	const setFocusToEditor = useCallback(() => {
		console.log('Focus please');
		(editorRefMain ?? editorRef).current?.editor.focus();
	}, [editorRefMain]);

	useEffect(() => {
		if (initalFocus) {
			setFocusToEditor();
			window.addEventListener('focus', setFocusToEditor);
		}
		return () => {
			window.removeEventListener('focus', setFocusToEditor);
		};
	}, [initalFocus, setFocusToEditor]);

	useEffect(() => {
		const editor = (editorRefMain || editorRef)?.current?.editor;
		if (editor) {
			editor.textCopied = '';
		}
	}, [tabSwitch, editorRefMain, editorRef]);

	const pasteNotificationId = useRef<pasteNotifcationIdInterface>({ id: null });

	const handleChange = useCallback((val: string) => {
		onChange?.(val);
	}, [onChange]);

	const copyText = useCallback(() => {
		const editor = (editorRefMain || editorRef)?.current?.editor;
		if (editor) {
			const text = editor.getSelectedText();
			editor.textCopied = text ?? '';
		}
	}, [editorRefMain, editorRef]);

	const handleSendPasteNotification = useCallback(() => {
		sendPasteNotification?.();
	}, [sendPasteNotification]);

	const debouncedNotification = useCallback(() => {
		if (pasteNotificationId.current.id) {
			clearInterval(pasteNotificationId.current.id);
		}
		pasteNotificationId.current.id = setTimeout(handleSendPasteNotification, 505);
	}, [handleSendPasteNotification]);

	const handlePaste = useCallback(() => {
		const editor = (editorRefMain || editorRef)?.current?.editor;
		if (editor) {
			const selection = editor.getSelection();
			const range = selection.getRange();
			if (!editor.textCopied) {
				if (notifyOnPaste) {
					debouncedNotification();
					message.error('Attention: Please do not use copy paste as it may result in disqualification.');
					return;
				}
			}
			editor.session.replace(range, editor.textCopied ?? '');
		}
	}, [editorRefMain, editorRef, notifyOnPaste, debouncedNotification]);

	useEffect(() => {
		const editor = (editorRefMain || editorRef)?.current?.editor;
		if (editor) {
			if (disableCopyPaste) {
				editorEvents.forEach((event) => {
					editor.container.addEventListener(event, stop, true);
				});
			} else {
				editorEvents.forEach((event) => {
					editor.container.removeEventListener(event, stop);
				});
			}

			if (notifyOnPaste) {
				pasteNotificationEvents.forEach((event) => {
					editor.container.addEventListener(event, handleSendPasteNotification, true);
				});
			}
		}

		return () => {
			if (editor) {
				editorEvents.forEach((event) => {
					editor.container.removeEventListener(event, stop);
				});

				pasteNotificationEvents.forEach((event) => {
					editor.container.removeEventListener(event, handleSendPasteNotification);
				});
			}
		};
	}, [disableCopyPaste, notifyOnPaste, handleSendPasteNotification, editorRefMain]);

	useEffect(() => {
		const editor = (editorRefMain || editorRef).current?.editor;
		if (editor) {
			if ((name === 'head' || name === 'tail') && readOnly && disableCopyPaste) {
				editor.container.style.pointerEvents = 'none';
			}
		}
	}, [name, readOnly, disableCopyPaste, editorRefMain]);

	useEffect(() => {
		if ((name === 'html' || name === 'css' || name === 'js') && disableCopyPaste) {
			(editorRefMain || editorRef).current.editor.on('change', pasteHandler);
		}
	}, [name, pasteHandler, editorRefMain, disableCopyPaste]);

	return (
		<AceEditor
			ref={editorRefMain || editorRef}
			className={className}
			style={{
				width: 'unset',
				height: 'unset',
				lineHeight: '17px',
				...style,
			}}
			name={name}
			mode={mode ?? 'text'}
			value={value}
			defaultValue={defaultValue}
			readOnly={readOnly}
			highlightActiveLine={false}
			showPrintMargin={false}
			fontSize={14}
			enableBasicAutocompletion
			enableLiveAutocompletion
			enableSnippets={!disableCopyPaste}
			tabSize={2}
			setOptions={{
				enableEmmet: false,
				showLineNumbers,
				highlightGutterLine: false,
				scrollPastEnd: false,
				useWorker: false,
				maxLines,
				minLines,
			}}
			onLoad={(editor) => {
				editor.renderer.setPadding(5);
				editor.renderer.setScrollMargin(5, 5, 0, 0);
				setTimeout(() => editor.resize(true), 100);
			}}
			editorProps={{ $blockScrolling: true }}
			onChange={handleChange}
			commands={
				disableCopyPaste
					? [
						{
							name: 'copy-text',
							bindKey: {
								win: 'ctrl-c|cmd-c',
								mac: 'ctrl-c|cmd-c',
							},
							exec() {
								copyText();
							},
						},
						{
							name: 'paste-text',
							bindKey: {
								win: 'ctrl-v|cmd-v',
								mac: 'ctrl-v|cmd-v',
							},
							exec() {
								(editorRefMain || editorRef).current?.editor?.off('change', pasteHandler);
								handlePaste();
								(editorRefMain || editorRef).current?.editor?.on('change', pasteHandler);
							},
						},
						{
							name: 'disable-copy-paste',
							bindKey: {
								win: 'ctrl-x|ctrl-shift-v|shift-del|cmd-x',
								mac: 'ctrl-x|ctrl-shift-v|shift-del|cmd-x',
							},
							exec() {
								if (notifyOnPaste) {
									debouncedNotification();
								}
							},
						},
						{
							name: 'undo',
							bindKey: {
								win: 'ctrl-z|cmd-z',
								mac: 'ctrl-z|cmd-z',
							},
							exec() {
								if (disableCopyPaste) {
									(editorRefMain || editorRef).current?.editor?.off('change', pasteHandler);
									(editorRefMain || editorRef).current?.editor.session.getUndoManager().undo(
										(editorRefMain || editorRef).current?.editor.getSession(),
									);
									(editorRefMain || editorRef).current?.editor?.on('change', pasteHandler);
								} else {
									(editorRefMain || editorRef).current?.editor.session.getUndoManager().undo(
										(editorRefMain || editorRef).current?.editor.getSession(),
									);
								}
							},
						},
						{
							name: 'redo',
							bindKey: {
								win: 'ctrl-shift-z|cmd-shift-z',
								mac: 'ctrl-shift-z|cmd-shift-z',
							},
							exec() {
								if (disableCopyPaste) {
									(editorRefMain || editorRef).current?.editor?.off('change', pasteHandler);
									(editorRefMain || editorRef).current?.editor.session.getUndoManager().redo(
										(editorRefMain || editorRef).current?.editor.getSession(),
									);
									(editorRefMain || editorRef).current?.editor?.on('change', pasteHandler);
								} else {
									(editorRefMain || editorRef).current?.editor.session.getUndoManager().redo(
										(editorRefMain || editorRef).current?.editor.getSession(),
									);
								}
							},
						},
					] : []
			}
		/>
	);
};
