import React, { useCallback } from 'react';
import {
	Switch, Route, useRouteMatch, Redirect,
} from 'react-router-dom';
import { NotFoundScreen } from './not-found';

export const ErrorScreen: React.FunctionComponent = () => {
	const match = useRouteMatch();

	const notFoundScreen = useCallback(() => <NotFoundScreen />, []);

	return (
		<Switch>
			<Route
				exact
				path={`${match.url}/404`}
				render={notFoundScreen}
			/>

			<Redirect to={`${match.url}/404`} />
		</Switch>
	);
};
