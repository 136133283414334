export enum SupportedLanguages {
	'Python 2' = 0,
	'Ruby' = 1,
	'Php' = 3,
	'JavaScript' = 4,
	'SQL' = 5,
	'C' = 7,
	'Java' = 8,
	'C#' = 10,
	'Bash Script' = 11,
	'C99' = 17,
	'C++11' = 18,
	'C++14' = 19,
	'Python 3' = 20,
	'MySql' = 23,
	'Oracle' = 24,
	'C++' = 77,
}

export enum TestCaseStatus {
	NotExecuted = 0,
	Running = 1,
	Passed = 2,
	Failed = 3,
	Unknown = 4,
}

export enum DashboardContentType {
	MCQ = 1,
	Subjective = 2,
	Coding = 3,
	MQ = 4,
	Project = 7,
	Web = 9,
}

export enum QuestionType {
	MCQ = 1,
	Subjective = 2,
	Coding = 4,
	MQ = 5,
	Web = 9,
}

export enum QuestionStatus {
	Pending = 0,
	Submitted = 1,
}

export enum QuizUserDetailsInputType {
	TextInput = 0,
	Select = 1,
	Checkbox = 2,
	Radio = 3,
}

export enum QuestionExecutionType {
	CompileOnly = 1,
	HiddenTestCases = 2,
	TestCasesWithResults = 3,
}

export const EditorLanguageModes: { [key: number]: string } = {
	7: 'c_cpp',
	17: 'c_cpp',
	77: 'c_cpp',
	18: 'c_cpp',
	19: 'c_cpp',
	4: 'javascript',
	8: 'java',
	11: 'sh',
	0: 'python',
	20: 'python',
	1: 'ruby',
	10: 'csharp',
	3: 'php',
	5: 'sql',
	23: 'sql',
	24: 'sql',
};

export enum CheatingType {
	Paste = 1,
	TabSwitch = 2,
	WebcamBlock = 6,
	fullScreen = 9,
	AiProctor = 11,
}

export enum AiProctorCheatingSubType {
	userExit = 1,
	userEnter = 2,
	illegalObject = 3,
	multipleUserDetected = 4,
}

export enum FullScreenEventType {
	In = 1,
	Out = 2,
}

export enum TabSwitchEventType {
	In = 1,
	Out = 2,
}

export enum WebcamStateKind {
	Disabled = 1,
	Streaming = 2,
	Blocked = 3,
	Loading = 4,
	Error = 5,
}

export enum ScreenShareState {
	Disabled = 1,
	Loading = 2,
	Active = 3,
	Error = 4,
}

export enum NetworkStatus {
	Online = 1,
	Pending = 2,
	Offline = 3,
}
