import React, { useCallback, useState } from 'react';
import {
	Button, Input, Form,
} from 'antd';

import './register-form.scoped.css';
import { APP_CONFIG } from '../../config';

console.log(APP_CONFIG.isChitkara);
interface RegisterFormValues {
	name: string
	email: string
	password: string
	quizCode: string
	mobile: string
	enrollmentId?: string
}

interface RegisterFormProps {
	onRegister: (
		name: string, email: string, password: string,
		quizCode: string, mobile: string, enrollmentId?: string,
	) => Promise<void>
}

export const RegisterForm: React.FunctionComponent<RegisterFormProps> = (props) => {
	const { onRegister: registerUser } = props;

	const [registering, setRegistering] = useState<boolean>(false);

	const handleRegisterFormSubmit = useCallback(async (values: RegisterFormValues) => {
		try {
			const {
				name, email, mobile, password, quizCode, enrollmentId,
			} = values;

			setRegistering(true);
			await registerUser(name, email.toLowerCase(), password, quizCode, mobile, enrollmentId);
		} catch (e) {
			setRegistering(false);
		}
	}, [registerUser]);

	return (
		<div className="sign-up-form-container">
			<Form
				layout="vertical"
				onFinish={handleRegisterFormSubmit}
			>
				<Form.Item
					label="full name *"
					name="name"
					rules={[{ required: true, whitespace: true, message: 'Please enter name!' }]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="email *"
					name="email"
					rules={[{
						required: true, whitespace: true, message: 'Please enter email!',
					}, {
						type: 'email', message: 'Please enter valid email',
					}]}
					normalize={(value, prevVal, prevVals) => value.trim()}
				>
					<Input />
				</Form.Item>
				{APP_CONFIG.isChitkara
					&& (
						<Form.Item
							label="roll number *"
							name="enrollmentId"
							rules={[
								{ required: true, whitespace: false, message: 'Please enter roll number!' },
								{
									pattern: /^[a-zA-Z0-9]+$/,
									message: 'Roll number should be alphanumeric character',
								},
							]}
						>
							<Input />
						</Form.Item>
					)}
				<Form.Item
					label="password *"
					name="password"
					rules={[{
						required: true, whitespace: true, message: 'Please enter password!',
					}, {
						min: 8, max: 32, message: 'Your password must be at least 8 characters and max, 32 characters long.',
					}, {
						pattern: new RegExp(/[A-Z]/),
						message: 'At least one capital letter required.',
					}, {
						pattern: new RegExp(/[a-z]/),
						message: 'At least one small letter required.',
					}, {
						pattern: new RegExp(/[0-9]/),
						message: 'At least one number required.',
					}, {
						pattern: new RegExp(/^\S+$/),
						message: 'Space is not allowed in password.',
					}, {
						pattern: new RegExp(/[!/@;<#$%^&>?/?>.,\\|{}[\]_-]/),
						message: 'At least one special character required.',
					}]}
				>
					<Input.Password />
				</Form.Item>

				<Form.Item
					label="test code *"
					name="quizCode"
					rules={[{ required: true, whitespace: true, message: 'Please enter the test code!' },
					{
						pattern: new RegExp(/^\d+$/),
						message: 'Please enter only numeric values',
					}]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="mobile number *"
					name="mobile"
					rules={[{
						required: true, whitespace: true, message: 'Please enter mobile number!',
					}, {
						min: 10, max: 10, message: 'Please enter valid mobile number!',
					},
					{
						pattern: new RegExp(/^\d+$/),
						message: 'Please enter only numeric values',
					}]}
					normalize={(value, prevVal, prevVals) => value.trim()}
				>
					<Input />
				</Form.Item>

				<Form.Item>
					<div className="form-actions">
						<Button
							type="primary"
							size="large"
							htmlType="submit"
							loading={registering}
						>
							sign up
						</Button>
					</div>
				</Form.Item>
			</Form>
		</div>
	);
};
