import { Slider } from 'antd';
import React, { useEffect, useState } from 'react';

import './font-change-button.scoped.css';
import { FontChangeSlider } from '../font-change-slider';

export const FontChangeButton: React.FunctionComponent = () => {
	const [fontValue, setFontValue] = useState(0);
	const [valueToShow, setValueToShow] = useState(0);

	useEffect(() => {
		if ('api' in window) {
			(window.api as any).receiveFromMain('zoom-change', (zoomValue) => {
				if (zoomValue && typeof zoomValue === 'number') {
					setValueToShow(zoomValue * 100 - 100);
				}
			});
		}
	}, []);

	useEffect(() => {
		console.log(fontValue);
		if ('api' in window) {
			(window.api as any).sendToMain('zoom-event', fontValue);
		}
		setValueToShow(fontValue);
	}, [fontValue, setValueToShow]);
	return (
		<div className="main-slider">
			<p>Font Size</p>
			<div
				className="font-slider"
			>
				<FontChangeSlider
					value={valueToShow}
					min={-50}
					max={50}
					setValueOfSlider={setFontValue}
				/>
			</div>
		</div>
	);
};
