import {
	Button, Divider, Form, message, Upload,
} from 'antd';
import type { UploadProps } from 'antd';
import React, {
	useCallback, useEffect, useRef, useState,
} from 'react';
import { UploadOutlined, CloseCircleFilled } from '@ant-design/icons';
import ReactQuill from 'react-quill';

import './attempt-subjective.scoped.css';
import 'react-quill/dist/quill.snow.css';

interface EditorProps {
	readonly?: boolean
	value?: string
	onChange?: (value: string) => void,
	disableCopyPaste?: boolean,
	sendPasteNotification: () => void,
}

const Editor: React.FunctionComponent<EditorProps> = (props) => {
	const {
		value: defaultValue,
		readonly, onChange,
		sendPasteNotification,
		disableCopyPaste,
	} = props;
	const [textCopied, setTextCopied] = useState<string>('');
	const [copyToken, setTokenValue] = useState<string>('');
	const editorRef = useRef<ReactQuill | null>(null);

	const handleCopy = useCallback(() => {
		if (!editorRef.current) return;
		const currentValue = editorRef.current.getEditor().getText();
		const selection = editorRef.current.getEditor().getSelection();
		if (!selection) return;
		const valueToSet = currentValue.slice(selection.index, selection.length);
		const token = crypto.randomUUID();
		setTokenValue(token);
		navigator.clipboard.writeText(token);
		setTextCopied(valueToSet);
	}, [setTextCopied, setTokenValue]);

	const handlePaste = useCallback(async () => {
		if (!editorRef.current) return;
		const token = await navigator.clipboard.readText();
		if (!token || token !== copyToken) {
			// handle Copy Error Herer
			message.error('Attention: Please do not use copy paste as it may result in disqualification.');
			sendPasteNotification();
			return;
		}

		if (editorRef.current) {
			const textToPaste = textCopied;
			const selection = editorRef.current.getEditor().getSelection();
			if (!selection) return;
			console.log(selection);
			const previousValue = editorRef.current.getEditor().getText();
			editorRef.current.getEditor().deleteText(selection.index, selection.length);
			editorRef.current.getEditor().insertText(selection.index, textCopied);
			editorRef.current.getEditor().setSelection({ index: selection.index, length: 0 });
		}
	}, [textCopied, copyToken, sendPasteNotification]);

	const handleKeyDownEvent = useCallback((ev: any) => {
		if (disableCopyPaste) {
			if ('key' in ev && typeof ev.key === 'string' && 'ctrlKey' in ev && ev.ctrlKey === true) {
				if (ev.key === 'v') {
					ev?.preventDefault();
					handlePaste();
				}
				if (ev.key === 'c') {
					ev?.preventDefault();
					handleCopy();
				}
			}
		}
	}, [handlePaste, handleCopy, disableCopyPaste]);

	return (
		<ReactQuill
			ref={editorRef}
			readOnly={readonly}
			theme="snow"
			defaultValue={defaultValue}
			onChange={onChange}
			onKeyDown={(ev) => handleKeyDownEvent(ev)}
		/>
	);
};

const uploadProp: UploadProps = {
	showUploadList: {
		removeIcon: <CloseCircleFilled />,
	},
};

interface SubjectiveFormValues {
	answer: string
	upldFile: any
}

interface AttemptSubjectiveProps {
	questionData: AttemptQuestionData
	disableCopyPaste?: boolean
	submitting: boolean
	onSubmit: (data: any) => Promise<void>
	setAttempt: (attemptData: unknown) => void
	getAttempt: () => any | null,
	sendPasteNotification: () => void
}

export const AttemptSubjective: React.FunctionComponent<AttemptSubjectiveProps> = (props) => {
	const {
		questionData,
		onSubmit,
		setAttempt,
		getAttempt,
		submitting: isSubmitting,
		disableCopyPaste,
		sendPasteNotification,
	} = props;
	const [form] = Form.useForm();

	const saveAttempt = useCallback(() => {
		setAttempt({
			value: form.getFieldValue('answer'),
		});
	}, [form, setAttempt]);

	const handleSubjectiveFormSubmit = useCallback(async (values: SubjectiveFormValues) => {
		saveAttempt();

		await onSubmit({
			txtSub: values.answer || '',
			upldFile: values.upldFile?.[0]?.originFileObj || '',
		});
	}, [onSubmit, saveAttempt]);

	const normFile = useCallback((e: any, ...arg: any) => {
		let file;
		if (Array.isArray(e)) {
			[file] = e.slice(-1);
		} else {
			[file] = e?.fileList?.slice(-1);
		}

		const SIZE_LIMIT = 2 * 1024 * 1024;

		if (!file) { return []; }

		if (file.size > SIZE_LIMIT) {
			message.error('attachment size cannot exceed 2MB.');

			return [];
		}

		return file ? [file] : [];
	}, []);

	useEffect(() => {
		const intervalId = setInterval(saveAttempt, 2000);

		return () => clearInterval(intervalId);
	}, [saveAttempt]);

	return (
		<div className="attempt-subjective-container">
			<div>
				<span className="info-text">Answer below:</span>
				<Divider style={{ margin: '0.5rem 0 1.5rem 0', width: '50%', minWidth: 'unset' }} />
				<div>
					<Form
						form={form}
						onFinish={handleSubjectiveFormSubmit}
					>
						<Form.Item
							name="answer"
							initialValue={(
								getAttempt()?.value
								|| questionData.questionTypeSubjective?.lastSubmitted
							)}
						>
							<Editor
								readonly={isSubmitting}
								disableCopyPaste
								sendPasteNotification={sendPasteNotification}
							/>
						</Form.Item>
						{
							questionData?.questionTypeSubjective?.isFileUpload && (
								<Form.Item
									name="upldFile"
									valuePropName="fileList"
									getValueFromEvent={normFile}
								>
									<Upload
										beforeUpload={() => false}
										multiple={false}
										/* eslint-disable react/jsx-props-no-spreading */
										{...uploadProp}
									>
										<Button icon={<UploadOutlined />}>Select file to upload</Button>
									</Upload>
								</Form.Item>
							)
						}
					</Form>
				</div>
			</div>
			<div className="submit-subjective-container">
				<Button
					type="primary"
					size="large"
					onClick={() => form.submit()}
					loading={isSubmitting}
				>
					submit
				</Button>
			</div>
		</div>
	);
};
