import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import { ErrorScreen } from './error-screen';
import { QuizScreen } from './quiz-screen';

export const MainScreen: React.FunctionComponent = () => (
	<Switch>
		<Route path="/error">
			<ErrorScreen />
		</Route>
		<Route path="/test">
			<QuizScreen />
		</Route>
		<Redirect to="/error" />
	</Switch>
);
