import React, {
	useState, useEffect, useCallback, useRef,
} from 'react';

import './browser-iframe.scoped.css';

import { APP_CONFIG } from '../../config';

import CQLogo from '../../assets/img/cq-logo.svg';
import ChitkaraLogo from '../../assets/img/chitkara.png';

const Logo = (APP_CONFIG.isChitkara) ? ChitkaraLogo : CQLogo;
interface BrowserIframeProps extends React.IframeHTMLAttributes<HTMLIFrameElement> {
	browserRef: React.Ref<HTMLIFrameElement>
}

export const BrowserIframe: React.FunctionComponent<BrowserIframeProps> = (props) => {
	const {
		src, srcDoc, title, sandbox, browserRef,
	} = props;

	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		setLoading(true);
	}, [src, srcDoc]);

	const handleFrameLoad = useCallback(() => {
		setLoading(false);
	}, []);

	return (
		<div className="browser-iframe-container">
			<iframe sandbox={sandbox} ref={browserRef} className="browser-iframe" title={title} srcDoc={srcDoc} src={src} onLoad={handleFrameLoad} />
			{
				loading && (
					<div className="browser-iframe-loading-container">
						<img className="loader-logo blink" src={Logo} alt="loader logo" />
						<p style={{ color: '#333333b5', fontSize: 16, textAlign: 'center' }}>Loading...</p>
					</div>
				)
			}
		</div>
	);
};
