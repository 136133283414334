export enum Platform{
	'browser' = 1,
	'app' = 2,
}

export function getRandomInteger(min: number, max: number): number {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

// HERE RSA CAN BE USED TO INCREASE THE ACCURACY OF THE TEST
export const currentPlatform = () : Platform => {
	if ('api' in window) {
		return Platform.app;
	}
	return Platform.browser;
};
