import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import styles from './font-change-slider.module.css';

interface FSProps {
	value: number,
	min: number,
	max: number,
	setValueOfSlider: (value: number) => void;
	style?: {
		[key: string]: string
	}
}

export const FontChangeSlider: React.FunctionComponent<FSProps> = (props: FSProps) => {
	const {
		min, max, setValueOfSlider, value,
	} = props;
	return (
		<div style={{ display: 'flex', flexGrow: '1', height: '100%' }}>
			<Tooltip title={`${value + 100}%`} trigger="hover">
				<input onInput={(event) => setValueOfSlider(parseInt(event.currentTarget.value, 10))} value={value} type="range" min={min} max={max} className={styles.slider} />
			</Tooltip>
		</div>
	);
};
