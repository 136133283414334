import React, {
	useCallback, useEffect, useState,
} from 'react';
import {
	Button, Modal, Spin, message,
} from 'antd';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import styles from './openInApp.module.css';

interface OpenInAppProps {
	isOpen: boolean
	handleClose: () => void
	downloadApp: () => Promise<void>
}

export const OpenInApp = (props: OpenInAppProps) => {
	const { isOpen, handleClose, downloadApp } = props;
	const [appInstalled, setAppInstalled] = useState<boolean | null>(null);
	useEffect(() => {
		// Changes app not installed to false after 2 sec
		if (window.navigator.userAgent.includes('Win')) {
			const id = setTimeout(() => {
				setAppInstalled(false);
			}, 2000);

			// Check One Front End.
			const checkFunction = () => {
				setAppInstalled(true);
				handleClose();
				clearInterval(id);
			};
			window.addEventListener('blur', checkFunction);
			setTimeout(() => {
				window.removeEventListener('blur', checkFunction);
			}, 100);

			// TODO: Implement Server Side Check As well
		} else {
			setAppInstalled(true);
		}
	}, [handleClose]);
	const handleDownload = useCallback(async () => {
		try {
			await downloadApp();
		} catch (error: any) {
			message.error(error?.message ?? error);
		}
	}, [downloadApp]);
	return (
		<Modal
			open={isOpen}
			onCancel={handleClose}
			footer={false}

		>
			<div style={{ minHeight: '100px' }}>
				{appInstalled === null
					&& (
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<Spin indicator={<LoadingOutlined style={{ fontSize: 50, color: 'orange' }} spin />} />
						</div>
					)}
				{
					appInstalled === true
					&& (
						<div style={{ paddingTop: '20px' }}>
							<p className={styles.appOnlyMessage}>You appear to have installed the app,</p>
							<p className={styles.appOnlyMessage}>but if not working please download the app.</p>
							<p className={styles.appOnlyMessage}>And try again.</p>
							<div
								style={{
									display: 'flex', gap: '20px', marginTop: '20px', alignItems: 'center',
								}}
							>
								<Button style={{ borderRadius: '5px' }} type="primary" onClick={handleDownload}>Download App</Button>
								<a href={`test-codequotient://${window.location.href}`}>Open Again</a>
							</div>
						</div>
					)
				}
				{appInstalled === false
					&& (
						<div style={{
							alignItems: 'left', justifyContent: 'left', paddingTop: '20px',
						}}
						>
							<p>App is not installed please download our app.</p>
							<Button type="primary" style={{ borderRadius: '5px' }} onClick={handleDownload}>Download App</Button>
						</div>
					)}
			</div>
		</Modal>
	);
};
